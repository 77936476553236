import React from 'react';
import { isMobile } from 'react-device-detect';
import { COLORS } from '../../themes/colors';
import { Image } from 'antd';
import IconClose from '../../assets/icon-close-black.svg';
import { useNavigate } from 'react-router';

const Banner = ({ handleClose }: { handleClose: () => void }) => {
  const styles = {
    main: {
      position: 'fixed',
      top: '0px',
      left: '0px',
      right: '0px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      zIndex: '9999',
      background: '#FFCC66',
      padding: '4px 20px'
    },
    message: {
      fontSize: '18px',
      color: COLORS.BLACK,
      cursor: 'pointer',
      userSelect: 'none'
    },
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/subscriptions?discount=10');
    handleClose();
  };


  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore 
    <div style={styles.main}>
      <div />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore  */}
      <div onClick={handleClick} style={styles.message}>Your minutes are running out. Click here to get more minutes with a 10% discount.</div>
      <div onClick={handleClose} className="cursor-pointer">
        <Image src={IconClose} preview={false} width={12} />
      </div>
    </div>
  );
};

export default Banner;
