// twitchAuth.ts


const TWITCH_CLIENT_ID = process.env.REACT_APP_TWITCH_CLIENT_ID;

const TWITCH_REDIRECT_URI: string =
  process.env.REACT_APP_TWITCH_RETURN_URL || '';
const TWITCH_SCOPE =
  'openid+user:read:follows+user:read:email+moderation:read&claims={"id_token":{"email":null,"email_verified":null,"picture":null,"preferred_username":null}}';
const LOGIN_URI = `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${TWITCH_CLIENT_ID}&redirect_uri=${TWITCH_REDIRECT_URI}&scope=${TWITCH_SCOPE}`;

const handleTwitchLogin = (): void => {
  window.location.href = LOGIN_URI;
};

export default handleTwitchLogin;
