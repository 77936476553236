import React, { useState, useEffect, useCallback } from 'react';
import './LoaderFacts.css';

// Import your JSON data containing the facts
import factsData from './facts.json';

function getRandomFacts(arr, num) {
  // Shuffle the array of facts randomly and return a slice of 'num' random facts
  const shuffled = arr.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, num);
}

function LoaderFacts({ setRefundButton, uniqueId }) {
  const [typedText, setTypedText] = useState('');
  const [sentenceIndex, setSentenceIndex] = useState(0);
  const [countdown, setCountdown] = useState('7:00');
  const [randomFacts, setRandomFacts] = useState([]);
  const [refundActive, setRefundActive] = useState(false); // State to track if refund is active
  const [timerOff, setTimerOff] = useState(false); // State to track if the timer is off

  const typingSpeed = 100; // Typing speed in milliseconds
  const breakDuration = 6000; // 3 seconds break between sentences
  const countdownUpdateInterval = 1000; // Update every second (1000 ms)

  const activateRefund = useCallback(() => {
    // Activate the refund action when the button is clicked
    setRefundButton(true); // Trigger the refund action
    setRefundActive(true); // Set refund as active
  }, [setRefundButton]);

  useEffect(() => {
    // Use the imported JSON data containing the facts
    const facts = factsData.facts;

    // Get a random selection of facts
    const randomFactsArray = getRandomFacts(facts, 50);
    setRandomFacts(randomFactsArray);

    const currentFact = randomFactsArray[sentenceIndex];
    let textIndex = 0;

    const interval = setInterval(() => {
      if (textIndex <= currentFact.length) {
        setTypedText(currentFact.substring(0, textIndex));
        textIndex++;
      } else {
        clearInterval(interval); // Clear the interval temporarily

        if (sentenceIndex < randomFactsArray.length - 1) {
          setTimeout(() => {
            setSentenceIndex(sentenceIndex + 1);
          }, breakDuration);
        } else {
          // When all sentences are typed, set timer off
          setTimerOff(true);
        }
      }
    }, typingSpeed);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [sentenceIndex]);

  useEffect(() => {
    // Check if there is a saved timer state in sessionStorage for this uniqueId
    const savedTimerState = sessionStorage.getItem(`timerState_${uniqueId}`);
    if (savedTimerState) {
      const { countdownValue, sentenceIndexValue } =
        JSON.parse(savedTimerState);
      setCountdown(countdownValue);
      setSentenceIndex(sentenceIndexValue);
    }

    const countdownInterval = setInterval(() => {
      // Split the countdown time into minutes and seconds
      const [minutes, seconds] = countdown.split(':').map(Number);

      if (minutes === 0 && seconds === 0) {
        clearInterval(countdownInterval); // Stop the countdown at 0:00
        if (!timerOff) {
          setTimerOff(true); // Set timer off if it's not already off
        }
      } else {
        // Calculate the new countdown time
        const newSeconds = seconds === 0 ? 59 : seconds - 1;
        const newMinutes = seconds === 0 ? minutes - 1 : minutes;

        // Format the new time as "mm:ss"
        const formattedTime = `${newMinutes
          .toString()
          .padStart(2, '0')}:${newSeconds.toString().padStart(2, '0')}`;

        setCountdown(formattedTime);

        // Save timer state in sessionStorage with the unique key
        sessionStorage.setItem(
          `timerState_${uniqueId}`,
          JSON.stringify({
            countdownValue: formattedTime,
            sentenceIndexValue: sentenceIndex,
          }),
        );
      }
    }, countdownUpdateInterval);

    return () => clearInterval(countdownInterval);
  }, [countdown, sentenceIndex, timerOff, uniqueId]);

  return (
    <div className="facts-container">
      {timerOff ? (
        <div className="vscode-font">
          <div className="center-text">
            <span>Your highlights should be ready any minute!</span>
            <span className="cursor"></span>
          </div>
        </div>
      ) : (
        <>
          <div className="center-middle">
            <div className="vscode-font"></div>
            <span className="timer-style">Estimate Time {countdown}</span>
            <div className="center-text">
              <span>{typedText}</span>
              <span className="cursor"></span>
            </div>
          </div>
        </>
      )}
      {/* Uncomment the following section to enable the refund button/message */}
      <div className="center-middle">
        {timerOff ? (
          refundActive ? (
            <span className="static-phrase">Refund Activated</span>
          ) : (
            <button className="refund-button" onClick={activateRefund}>
              Activate Refund
            </button>
          )
        ) : (
          <>
            {timerOff && !refundActive && (
              <button className="refund-button" onClick={activateRefund}>
                Activate Refund
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default LoaderFacts;
