import './CancelSubscriptionModal.less';

interface ISubscriptionCancelledProps {
  handleCloseModal: () => void;
}

export const SubscriptionCancelled = ({ handleCloseModal }: ISubscriptionCancelledProps) => {
  const handleContinue = () => {
    handleCloseModal();
  }

  return (
    <div className='sub-cancelled'>
      <div className='title'>Subscription Cancelled!</div>

      <div className="suggest-gift__buttons">
        <div onClick={handleContinue} className="suggest-gift__button">
          Continue
        </div>
      </div>
    </div>
  )
}