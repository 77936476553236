import { Modal, Button, Typography, Image, message } from 'antd';
import { useContext, useMemo } from 'react';
import { COLORS } from '../../themes/colors';
import { UserContext } from '../../data/userContext';
import { useNavigate } from 'react-router';
import { ReactComponent as IconTwitch } from '../../assets/icon-twitch-black.svg';
import IconClose from '../../assets/modal-close.svg';
import handleTwitchLogin from '../../utils/twitchAuth';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import { GoogleOutlined } from '@ant-design/icons';
import { postAudit } from '../../services/audit.service';

export interface ILoginModalProps {
  open: boolean;
  onClose: () => void,
}

export const LoginModal = ({ open, onClose }: ILoginModalProps) => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [messageApi, contextHolder] = message.useMessage();
  const hideLoginModals = localStorage.getItem('hideLoginModals');

  const handleGoogleError = (error: any) => {
    messageApi.error('Google login error', 3);
    console.error('Google login error:', error);
    postAudit({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      user_action: `login error - google - ${error}`,
      user_id: userContext?.user?.id
    });
  };

  const handleSuccessGoogleLogin = async (tokenResponse: any) => {
    if (tokenResponse) {
      if (tokenResponse.code) {
        const response = await axios
          .post('/auth/social/twitch/login-or-register', {
            code: tokenResponse.code,
            referral_key: localStorage.getItem('ref') || '',
            is_mobile_login: isMobile,
            type: 'google',
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
            }
          });

        if (response?.status === 200) {
          localStorage.removeItem('ref');
          userContext?.setUser(response.data.user);
          if (!hideLoginModals) navigate('/spikes/welcome');
          else navigate('/spikes/videos');
        }
      } else {
        console.error('No code provided by google login', tokenResponse);
      }
    }
  };

  const handleGoogleLogin = userContext?.user ? null : useGoogleLogin({
    onSuccess: handleSuccessGoogleLogin,
    flow: 'auth-code',
    scope: 'openid email profile',
    redirect_uri: process.env.REACT_APP_GOOGLE_RETURN_URL,
    ux_mode: 'redirect',
    onError: handleGoogleError,
    onNonOAuthError: handleGoogleError
  });

  const styles = useMemo(() => {
    return {
      modalStyle: {
        borderRadius: 10,
        overflow: 'hidden',
      },
      modalTitle: {
        fontSize: '20px',
        fontWeight: 600,
        marginBottom: 0,
        textAlign: 'center',
        color: COLORS.PRIMARY
      },
      modalBody: {
        background: COLORS.MODAL_GRAY_BACKGROUND,
        padding: '40px',
      },
      buttonTitle: {
        fontSize: 16,
        fontWeight: 400,
        marginBottom: 15,
        marginTop: 28
      },
    } as const;
  }, []);

  return (
    <Modal
      open={open}
      footer={null}
      bodyStyle={styles.modalBody}
      style={styles.modalStyle}
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      onCancel={onClose}
      closable={false}
      maskClosable={false}
      width={600}
      centered
    >
      {contextHolder}
      <div style={{ position: 'absolute', top: 20, right: 20 }} className='cursor-pointer' onClick={onClose}>
        <Image src={IconClose} preview={false} width={24} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography style={styles.modalTitle}>Please continue by logging in</Typography>
        <Typography style={styles.buttonTitle}>For Creators</Typography>
        {handleGoogleLogin &&
          <Button onClick={handleGoogleLogin} className="button" type='primary'>
            <GoogleOutlined style={{ display: 'flex' }} width={28} height={28} />Login with Google
          </Button>
        }
        <Typography style={styles.buttonTitle}>For Live Creators</Typography>
        <Button style={{ background: '#8D41FE', color: '#000' }} onClick={handleTwitchLogin} className="button button--dark" type='primary'>
          <IconTwitch width={24} height={24} style={{ color: 'black', marginRight: 10 }} />
          Login with Twitch
        </Button>
      </div>
    </Modal>
  );
};

export default LoginModal;