import React, { useState, useEffect } from 'react';
import './ThemeToggle.less';
import '@theme-toggles/react/css/Expand.css';
import { Expand } from '@theme-toggles/react';

const ThemeToggle: React.FC = () => {
  const [theme, setTheme] = useState<'light' | 'dark'>(
    () => (localStorage.getItem('theme') as 'light' | 'dark') || 'light'
  );

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') as 'light' | 'dark';
    if (savedTheme) {
      document.documentElement.setAttribute('data-theme', savedTheme);
    } else {
      document.documentElement.setAttribute('data-theme', theme);
    }
  }, [theme]);

  return (
    <Expand
      className="theme-toggle"
      style={{ color: 'var(--bg-brand-primary)' }}
      toggled={theme === 'dark'}
      toggle={toggleTheme}
      duration={400}
    />
  );
};

export default ThemeToggle;
