import { DISCORD_LINK } from "./constants/content-constants";

export const STRUCTURED_DATA_HOME_PAGE = {
  '@context': 'https://schema.org',
  '@type': 'NGO',
  'name': 'Spikes Studio',
  'alternateName': 'Spikes Studio - Ai Video Editor',
  'url': 'https://spikes.studio/',
  'logo': 'https://spikes.studio/static/media/spikes-logo.71cd7aeea71f4d4706ebf1384a469cce.svg',
  'sameAs': [
    'https://www.youtube.com/@SpikesStudio',
    'https://www.instagram.com/spikes_studio/',
    'https://twitter.com/Spikes_St',
    DISCORD_LINK,
    'https://www.tiktok.com/@spikesstudio'
  ]
};

export const STRUCTURED_DATA_FAQ = {
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  'mainEntity': [{
    '@type': 'Question',
    'name': 'Is Spikes Studio free?',
    'acceptedAnswer': {
      '@type': 'Answer',
      'text': 'Yes, Spikes offers a free plan that renews every month without requiring a credit card. Additionally, users have the flexibility to upgrade their plan whenever they choose.'
    }
  }, {
    '@type': 'Question',
    'name': 'How does Spikes select my moments?',
    'acceptedAnswer': {
      '@type': 'Answer',
      'text': 'Spikes AI is like a smart friend who analyzes community behaviour, sound, video, and even notices your gameplay. It takes all of these things into account to do its job. As you keep using it, it gets better at showing you what you want.'
    }
  }, {
    '@type': 'Question',
    'name': 'Does Spikes add watermark to the videos?',
    'acceptedAnswer': {
      '@type': 'Answer',
      'text': 'Absolutely not! We understand the importance of maintaining the integrity and professionalism of your content. Therefore, we do not use watermarks on any videos, not even in our free plan. We believe in providing our users with a seamless and unobtrusive experience, allowing them to showcase their creativity and ideas without any distractions.'
    }
  }, {
    '@type': 'Question',
    'name': 'What types of content does Spikes support?',
    'acceptedAnswer': {
      '@type': 'Answer',
      'text': 'Spikes is a flexible platform tailored to creators of all content types. Regardless of your preference - whether it is casual chats, sports, gaming, ASMR, or any other genre, Spikes has got you covered.'
    }
  }, {
    '@type': 'Question',
    'name': 'Am I able to upload my own Twitch clips and files from my computer?',
    'acceptedAnswer': {
      '@type': 'Answer',
      'text': 'At present, Spikes allows you to search and edit Twitch clips to your liking. We are continually enhancing our features and will soon provide the ability to upload and edit files directly from your computer as well.'
    }
  }]
};

export const STRUCTURED_DATA_ABOUT_PAGE = {
  '@context': 'https://schema.org',
  '@type': 'About',
  'mainEntityOfPage': {
    '@type': 'WebPage',
    '@id': 'https://spikes.studio/about'
  },
  'headline': 'About Us',
  'description': 'About Us',
  'image': 'https://spikes.studio/static/media/spikes-logo.71cd7aeea71f4d4706ebf1384a469cce.svg',
  'author': {
    '@type': 'Organization',
    'name': 'Spikes Studio',
    'url': 'https://spikes.studio/Spikes-studio-author-page'
  },
  'publisher': {
    '@type': 'Organization',
    'name': 'Spikes Studio',
    'logo': {
      '@type': 'ImageObject',
      'url': 'https://spikes.studio/static/media/spikes-logo.71cd7aeea71f4d4706ebf1384a469cce.svg'
    }
  },
  'datePublished': '2023-08-28',
  'dateModified': '2023-09-25'
};

export const STRUCTURED_DATA_TERMS_PAGE = {
  '@context': 'https://schema.org',
  '@type': 'About',
  'mainEntityOfPage': {
    '@type': 'WebPage',
    '@id': 'https://spikes.studio/terms'
  },
  'headline': 'Terms and Conditions',
  'description': 'Terms and Conditions of Spikes Studio',
  'image': 'https://spikes.studio/static/media/spikes-logo.71cd7aeea71f4d4706ebf1384a469cce.svg',
  'author': {
    '@type': 'Organization',
    'name': 'Spikes Studio',
    'url': 'https://spikes.studio/Spikes-studio-author-page'
  },
  'publisher': {
    '@type': 'Organization',
    'name': 'Spikes Studio',
    'logo': {
      '@type': 'ImageObject',
      'url': 'https://spikes.studio/static/media/spikes-logo.71cd7aeea71f4d4706ebf1384a469cce.svg'
    }
  },
  'datePublished': '2023-08-28',
  'dateModified': '2023-09-25'
};

export const STRUCTURED_DATA_PRIVACY_PAGE = {
  '@context': 'https://schema.org',
  '@type': 'Privacy',
  'mainEntityOfPage': {
    '@type': 'WebPage',
    '@id': 'https://spikes.studio/privacy'
  },
  'headline': 'Privacy Policy',
  'description': 'Privacy Policy of Spikes Studio',
  'image': 'https://spikes.studio/static/media/spikes-logo.71cd7aeea71f4d4706ebf1384a469cce.svg',
  'author': {
    '@type': 'Organization',
    'name': 'Spikes Studio',
    'url': 'https://spikes.studio/Spikes-studio-author-page'
  },
  'publisher': {
    '@type': 'Organization',
    'name': 'Spikes Studio',
    'logo': {
      '@type': 'ImageObject',
      'url': 'https://spikes.studio/static/media/spikes-logo.71cd7aeea71f4d4706ebf1384a469cce.svg'
    }
  },
  'datePublished': '2023-08-28',
  'dateModified': '2023-09-25'
};
