import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Image, Tooltip, Typography, message } from 'antd';
import { COLORS } from '../../../themes/colors';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { Player } from '../../player';
import { SpikeScore } from '../../spike-score';
import {
  IClip,
  IClipEdit,
  IVideoClip,
} from '../../../data/intefaces/stream.interface';
import { IStreamer } from '../../../data/intefaces/streamer.interface';
import { formatSeconds, isInt, secondsToTime } from '../../../utils/generic';
import { EClipType } from '../../buttons/share-button/ShareButton';
import lottie from 'lottie-web';
import loader_lottie from '../../../assets/Lottie/loader_lottie.json';

import { ReactComponent as IconCopySVG } from '../../../assets/icon-copy.svg';
import IconCopy from '../../../assets/icon-copy.svg';
import Loader from '../../../assets/loader.gif';
import IconEdit from '../../../assets/edit-icon.svg';
import IconDownload from '../../../assets/simple-download-icon.svg';
import IconOrangeStar from '../../../assets/orange-star.svg';
import IconEye from '../../../assets/eye-icon.svg';
import BluredQRCode from '../../../assets/blured-qr-code.png';
import IconShareTwitter from '../../../assets/icon-twitter-waitinglist.svg';
import IconShareTelegram from '../../../assets/icon-telegram-waitinglist.svg';
import IconShareFacebook from '../../../assets/icon-facebook-waitinglist.svg';
import IconShareGmail from '../../../assets/icon-gmail-waitinglist.svg';
import IconShareWhatsapp from '../../../assets/icon-whatsapp-waitinglist.svg';

import { EGeneratedClipType } from '../../../data/enums/clip-type.enum';
import { EAuditAction } from '../../../data/intefaces/audit.interface';
import { postAudit } from '../../../services/audit.service';
import { UserContext } from '../../../data/userContext';
import { DeleteClipModal } from '../../delete-clip-modal';
import QRCode from 'react-qr-code';
import { useDetectClickOutside } from 'react-detect-click-outside';

import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from 'react-share';
import { DeleteOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { BlockedEditorModal } from '../../blocked-editor-modal';

const LOADER_TEXT = [
  'We׳re editing your video',
  'This may take up to 3 minutes',
  'Server 2 is on their phone',
  'Server 1 is killing it on "Only Up"!',
  'Server 1 is enjoying your clip, wonderful! <3',
];

const Emotions = [
  {
    title: 'surprising',
    emoji: '😄',
    tooltip: 'This video is classified as \'Funny\'',
  },
  {
    title: 'scary',
    emoji: '😱',
    tooltip: 'This video is classified as \'Scary\'',
  },
  {
    title: 'rage',
    emoji: '😡',
    tooltip: 'This video is classified as \'Rage\'',
  },
  {
    title: 'interesting',
    emoji: '👀',
    tooltip: 'This video is classified as \'Interesting\'',
  },
  {
    title: 'none',
    emoji: '😶',
    tooltip: 'We couldn\'t detect any emotion here',
  },
];

export interface ISpikeCardNewProps {
  clip: IClip & Partial<IClipEdit> & IVideoClip;
  platformStreamId: string;
  isForYou?: boolean;
  isVisible: boolean;
  isTwitchCard?: boolean;
  displayClipType?: boolean;
  displayEmotion?: boolean;
  enableRemove?: boolean;
  enableShare?: boolean;
  enableEdit?: boolean;
  enablePreview?: boolean;
  onRemoveCard?: (clipId: string) => void;
  isUploadsTab?: boolean;
  isTwitchPage?: boolean;
  withOverlay?: boolean;
  platformUrl?: string;
  isYoutubeCard?: boolean;
  
}

export const SpikeCardNew = memo((props: ISpikeCardNewProps) => {
  const {
    clip,
    platformStreamId,
    isForYou = false,
    isVisible,
    isTwitchCard = false,
    displayClipType = true,
    displayEmotion = false,
    enableRemove = false,
    enableShare = true,
    enableEdit = true,
    enablePreview = false,
    onRemoveCard,
    isUploadsTab = false,
    isTwitchPage = false,
    withOverlay = false,
    isYoutubeCard = false,
    platformUrl
  } = props;
  const navigate = useNavigate();
  const playerRef = useRef(null);
  const userContext = useContext(UserContext);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showBlockedEditorModal, setShowBlockedEditorModal] = useState<boolean>(false);
  const [selectedVersion, setSelectedVersion] = useState<EClipType>(
    clip.mobile_download_url ? EClipType.Mobile : EClipType.Desktop,
  );
  const [loaderTextId, setLoaderTextId] = useState(0);
  const [openLargeQRCode, setOpenLargeQRCode] = useState(false);
  const [triggerGlowAnimation, setTriggerGlowAnimation] = useState<boolean>(false);
  const lottieRef = useRef(null);

  const getHDAvaliableForUser = (user : IStreamer | null | undefined) : boolean | undefined => {
    if (user === null || user === undefined) {
      return false;
    }

    if (user?.permissions) {
      return userContext?.user?.permissions.hd_enabled;
    }

    return userContext?.user?.hd_available;
  };

  const isHdAvailable =  getHDAvaliableForUser(userContext?.user);  //userContext?.user?.hd_available;
  const subscriptions = userContext?.user?.subscriptions;

  const USER_NOT_SUBSCRIBED =
    subscriptions == null || subscriptions.length == 0;

  let duration =
    clip.start_time_in_event != null &&
      clip.end_time_in_event != null &&
      clip.start_time_in_event >= 0 &&
      clip.end_time_in_event >= 0
      ? clip.end_time_in_event - clip.start_time_in_event
      : clip.end_time - clip.start_time;

  // if (isTwitchCard && !clip.start_time_in_event && !clip.end_time_in_event) {
  //   duration = Math.round(clip.duration || 0);
  // }

  if (
    isTwitchCard ||
    clip.clip_type === EGeneratedClipType.TWITCH ||
    clip.clip_type === EGeneratedClipType.TWITCH_UPLOAD ||
    isYoutubeCard
  ) {
    duration = Math.round(clip.duration || 0);
  }

  // if (duration == 0) {
  //   console.log(!isUndefined(clip.start_time_in_event) && !isUndefined(clip.end_time_in_event) &&
  //   clip.start_time_in_event >= 0 && clip.end_time_in_event >= 0);
  // }

  const getLoadingClipId = () => {
    const storedData = localStorage.getItem('loadingClipId');
    return storedData ? JSON.parse(storedData) : null;
  };


  useEffect(() => {
    if (lottieRef.current) {
      lottie.loadAnimation({
        container: lottieRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: loader_lottie,
      });
    }
  }, []);

  useEffect(() => {
    if (getLoadingClipId() === clip?.id) {
      setTriggerGlowAnimation(true);
      setTimeout(() => {
        setTriggerGlowAnimation(false);
        clearLocalStorage();
      }, 900);
    }
  }, [getLoadingClipId()]);


  // Function to clear the data in localStorage
  const clearLocalStorage = () => {
    localStorage.removeItem('loadingClipId');
  };

  useEffect(() => {
    if (clip.status === 'in progress' && getLoadingClipId() === clip.id) {
      clearLocalStorage();
    }
  }, [clip.status]);

  useEffect(() => {
    if (clip.status === 'ready') return;

    const timeout = setTimeout(
      () => setLoaderTextId((loaderTextId + 1) % LOADER_TEXT.length),
      4000,
    );
    return () => {
      clearTimeout(timeout);
    };
  }, [loaderTextId, clip.status]);

  useEffect(() => {
    
    if (clip.clip_type !== 'spike' && clip.status === 'ready' && clip.mobile_download_url) {
      setSelectedVersion(EClipType.Mobile);
    }

    if (clip.clip_type === 'spike' && clip.status === 'ready' && clip.download_url) {
      setSelectedVersion(EClipType.Desktop);
    }
  }, [clip.status]);

  const disabled = clip.status == 'in progress' || getLoadingClipId() === clip.id || isTwitchPage;


  const styles = useMemo(() => {
    return {
      SpikeCardNewContainer: {
        backgroundColor: 'transparent',
        marginTop: 115,
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
      },
      stepCardBody: {
        borderRadius: '20px',
        border: '1px solid rgba(0, 239, 248, 0.03)',
        backgroundColor: isTwitchCard ? 'rgba(255, 255, 255, 0.05)' : '#10272f',
        padding: 20,
      },
      remove: {
        position: 'absolute',
        top: -115,
        right: 10,
        width: 24,
        height: 24,
        zIndex: 100,
        borderRadius: '50%',
        background: '#a54047',
        paddingLeft: 4,
      },
      emotion: {
        position: 'absolute',
        top: -95,
        left: 3,
        width: 38,
        height: 38,
        zIndex: 100,
        borderRadius: '50%',
        background: 'rgba(255, 255, 255, 0.1)',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        paddingLeft: 4,
        fontSize: 28,
      },
      clipType: {
        position: 'absolute',
        top: 9,
        left: 7,
        zIndex: 100,
      },
      emotionIcon: {
        marginTop: -5,
        marginLeft: -5,
        display: 'block',
      },
      label: {
        color: COLORS.LABEL,
        fontSize: 14,
        fontWeight: 200,
      },
      viewers: {
        color: COLORS.PRIMARY,
        fontSize: 14,
        fontWeight: 500,
      },
      duration: {
        position: 'absolute',
        top: -95,
        left: isForYou ? 50 : 30,
        background: isTwitchCard ? 'rgb(21, 1, 55)' : 'rgb(0, 25, 34)',
        zIndex: 100,
        fontSize: 12,
        padding: '1px 5px',
        borderRadius: 4,
      },
      playerContainer: {
        overflow: 'hidden',
        border: '1px solid rgba(0, 239, 248, 0.1)',
        position: 'relative',
        height: 280,
        background: COLORS.BLACK,
      },
      loaderContainer: {
        background: COLORS.BLACK,
        overflow: 'hidden',
        border: '1px solid rgba(0, 239, 248, 0.1)',
        position: 'relative',
        height: 280,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      },
      clipDetailsContainer: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-between',
      },
      spikeTimeRange: {
        color: COLORS.LABEL,
        fontWeight: 200,
      },
      divider: {
        borderLeftColor: COLORS.DIVIDER,
        height: 15,
      },
      //
      videosBlock: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      lightningButton: {
        borderLeft: `1px solid ${COLORS.BACKGROUND_DARK_GRAY}`,
        width: 56,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      editButton: {
        // borderLeft: `1px solid ${COLORS.BACKGROUND_DARK_GRAY}`,
        width: 56,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 18,
        backgroundColor: disabled ? COLORS.GRAY_DISABLED : COLORS.PRIMARY,
        color: COLORS.BLACK,
        borderRadius: disabled ? '0 4px 0 0' : 8,
        boxShadow: '0px 0px 5px 7px rgba(0,0,0,0.1)',
        height: '100%',
        cursor: disabled ? 'not-allowed' : 'pointer'
      },
      viewsButton: {
        borderLeft: `1px solid ${COLORS.BACKGROUND_DARK_GRAY}`,
        // width: 56,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 15px'
      },
      disabledEdit: {
        background: COLORS.GRAY_DISABLED,
        borderRadius: '0 4px 0 0'
      },
      videosContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '24px 48px 0 48px',
        backgroundColor: COLORS.GRAY_BACKGROUND,
        borderRadius: '0 0 8px 8px',
      },
      carouselControllersContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '24px',
      },
      verticalDivider: {
        height: 40,
        width: 1.5,
        backgroundColor: COLORS.LIGHT_GRAY_TEXT,
        margin: '0 20px',
      },
      videoItem: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        maxWidth: 624,
        minWidth: 624,
        position: 'relative'
      },
      overlay: {
        cursor: 'pointer',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 9999,
        position: 'absolute'
      },
      videoTopInfo: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '13px 24px',
        alignItems: 'center',
        flex: 1,
      },
      videoLengthText: {
        fontSize: '16px',
        fontWeight: 400,
      },
      videoTime: {
        fontSize: '20px',
        fontWeight: 400,
      },
      smallVerticalDivider: {
        margin: '0 18px',
        width: 1.5,
        height: 14,
        backgroundColor: COLORS.LIGHT_GRAY_TEXT,
      },
      bottomControllsBox: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: COLORS.GRAY_CONTROLS,
        borderRadius: '0 0 8px 8px'
      },
      downloadBox: {
        display: 'flex',
        padding: '15px 22px',
        alignItems: 'center',
      },
      downloadDevice: {
        marginLeft: '16px',
        fontSize: '15px',
        fontWeight: 400,
        marginRight: 4,
        lineHeight: '20px',
        wordBreak: 'normal',
        width: 'min-content'
      },
      downloadQualityBox: {
        height: '48px',
        width: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        backgroundColor: COLORS.PRIMARY,
        marginLeft: 8,
        cursor: 'pointer',
        position: 'relative'
      },
      downloadQualityText: {
        fontSize: '16px',
        color: COLORS.BACKGROUND_DARK_GRAY,
        fontWeigth: 400,
      },
      downloadQualityBoxDisabled: {
        backgroundColor: COLORS.GRAY_BACKGROUND,
      },
      disabledClick: {
        pointerEvents: 'none',
        cursor: 'not-allowed',
      },
      downloadQualityTextDisabled: {
        color: COLORS.GRAY_DISABLED,
      },
      qrCodeContainer: {
        height: 76,
        width: 78,
        padding: 8,
        background: COLORS.WHITE,
        borderRadius: '0 0 8px 0',
        cursor: 'pointer'
      },
      clipTypeItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.LIGHT_GRAY_TEXT,
        padding: '1px 10px',
        color: COLORS.GRAY_CONTROLS,
        fontSize: '16px',
        borderRadius: 4
      },
      orangeStar: {
        position: 'absolute',
        height: '16px',
        width: '16px',
        top: -29,
        right: -41,
      },
      largeQRCode: {
        height: '280px',
        width: '280px',
        padding: '8px',
        background: COLORS.WHITE,
        borderRadius: '0px 0px 8px',
        zIndex: '999',
        cursor: 'pointer'
      },
      largeQRCodeContainer: {
        position: 'absolute',
        right: '0',
        bottom: '0',
      },
      socialIconsContainer: {
        backgroundColor: COLORS.GRAY_CONTROLS,
        padding: '6px 10px',
        display: 'flex',
        borderRadius: '6px 6px 0 0',
        alignItems: 'center',
        justifyContent: 'space-between'
      },
      viewsText: {
        fontSize: '20px',
        fontWeight: 400,
        color: COLORS.WHITE,
        marginRight: 8,
      },
      copyIconContainer: {
        borderLeft: '1px solid rgb(34, 34, 34)',
        paddingLeft: 10
      },
      hashtagContainer: {
        marginTop: 10,
        gap: 10,
        maxWidth: '624px'
      },
      hashtagsText: {
        color: '#787878',
        display: 'inline'
      },
      hashtagsHeading: {
        color: COLORS.PRIMARY,
        display: 'inline'
      }
    } as const;
  }, [isTwitchCard, isForYou, disabled]);

  const editClicked = (preview = false) => {
    if (isMobile) {
      setShowBlockedEditorModal(true);
      return;
    }
    if (disabled) return;
    if (!enableEdit) {
      return;
    }

    //* @ts-expect-error undefined */
    // playerRef?.current?.pause();
    // navigate('/spikes/edit', { state: { clip, platformStreamId, preview } });
    // const fromUploads = location.pathname.includes('/uploads');
    navigate('/edit', { state: { clip, platformStreamId, preview, fromSpikesPage: !isYoutubeCard && !isUploadsTab, fromUploads: !isYoutubeCard && isUploadsTab, fromVideos: isYoutubeCard } });
  };

  const copyTimestamp = () => {
    if (isYoutubeCard) {
      if (clip.platform_url.includes('watch?v=')) {
        // Parse the URL to get the query string
        const queryString = new URL(clip.platform_url).search;

        // Create a URLSearchParams object to extract query parameters
        const params = new URLSearchParams(queryString);

        // Get the value of the "v" parameter
        const videoId = params.get('v');

        navigator.clipboard.writeText(
          `https://youtu.be/${videoId}?t=${clip.start_time.toFixed()}`);
      } else {
        navigator.clipboard.writeText(
          `${clip.platform_url}?t=${clip.start_time.toFixed()}`);
      }
    } else {
      navigator.clipboard.writeText(
        `https://www.twitch.tv/videos/${platformStreamId}?t=${secondsToTime(
          clip.start_time,
        )
          .replace(':', 'h')
          .replace(':', 'm')}s`,
      );
    }

    message.success({
      content: 'Timestamp copied!',
      style: {
        color: COLORS.BLACK,
      },
    });

    postAudit({
      user_action: EAuditAction.AuditActionCopySpikeTimestamps,
      clip: clip.id,
    });
  };

  const copySourceURL = () => {
    if (platformUrl) {
      navigator.clipboard.writeText(platformUrl);
    } else if (platformStreamId) {
      navigator.clipboard.writeText(`https://www.twitch.tv/videos/${platformStreamId}`);
    }
    if (!platformUrl && !platformStreamId) {
      message.error({
        content: 'No source URL found',
        style: {
          color: COLORS.BLACK,
        },
      });
    } else {
      message.success({
        content: 'Source URL copied!',
        style: {
          color: COLORS.BLACK,
        },
      });
    }
  };


  const copyPlatformUrl = () => {
    navigator.clipboard.writeText(clip.platform_url || '');
    message.success({
      content: 'Clip URL copied!',
      style: {
        color: COLORS.BLACK,
      },
    });

    postAudit({
      user_action: EAuditAction.AuditActionCopyTwitchPlatormUrl,
      clip: clip.id,
    });
  };

  const download = (isMobile?: boolean, isHD?: boolean) => {
    // if (isHD) { console.log('HD quality download'); };
    message.loading({
      content: 'Downloading...',
      style: {
        color: COLORS.BLACK,
      },
    });

    const downloadUrl = !isMobile
      ? clip.desktop_download_url || clip.download_url
      : clip.mobile_download_url;

    fetch(downloadUrl!, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/mp4',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `spike_${clip.clip_order.toString()}_${clip.clip_ranking.toString()}_${isMobile ? 'Mobile' : 'Desktop'
          }.mp4`,
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });

    postAudit({
      user_action: EAuditAction.AuditActionDownload,
      clip: clip.id,
    });
  };

  const formatClipType = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (clip.clip_type === EGeneratedClipType.MANUAL_SPIKE || clip.clip_type === 'manual_spike') return 'Manual';
    if (clip.clip_type === EGeneratedClipType.AI_MANUAL_COMBINED) return 'AI Clip';
    if (clip.clip_type === EGeneratedClipType.TWITCH || clip.clip_type === EGeneratedClipType.TWITCH_UPLOAD) return 'Twitch Clip';
    if (clip.clip_type === EGeneratedClipType.SPIKE) return 'AI Spike';
    if (clip.clip_type === EGeneratedClipType.UPLOAD) return 'Video AI';
    else return clip.clip_type;
  };


  const isTwitchClip = clip.clip_type === EGeneratedClipType.TWITCH || clip.clip_type === EGeneratedClipType.TWITCH_UPLOAD;

  const downloadUrl = clip.mobile_download_url ||
    clip.desktop_download_url ||
    clip.download_url;

  const copyUrl = () => {
    navigator.clipboard.writeText(downloadUrl);
    message.success({
      content: 'Video URL copied!',
      style: {
        color: COLORS.BLACK,
      },
    });

    postAudit({
      user_action: EAuditAction.AuditActionDownload,
      clip: clip.id,
    });
  };

  const copyHashtags = () => {
    if (getHashtags()) {
      navigator.clipboard.writeText(getHashtags().join(' '));
      message.success({
        content: 'Hashtags copied!',
        style: {
          color: COLORS.BLACK,
        },
      });
    }
  };

  const copyDescription = () => {
    if (clip?.description) {
      navigator.clipboard.writeText(clip?.description);
      message.success({
        content: 'Description copied!',
        style: {
          color: COLORS.BLACK,
        },
      });
    }
  };

  const copyHeadline = () => {
    if (clip?.headline) {
      navigator.clipboard.writeText(clip?.headline);
      message.success({
        content: 'Headline copied!',
        style: {
          color: COLORS.BLACK,
        },
      });
    }
  };

  const getHashtags = () => {
    if (clip.tags) {
      try {
        const validJsonString = clip.tags.replace(/'/g, '"');
        const array = JSON.parse(validJsonString);
        return array;
      } catch (error) {
        console.error('Error parsing hashtags:', error);
        return null;
      }
    } else {
      return null;
    }
  };

  const handleConfirmDeletion = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteClip = () => {
    onRemoveCard && onRemoveCard(clip.id);
    setShowDeleteModal(false);
  };

  return (
    <div>
      <BlockedEditorModal open={showBlockedEditorModal} onClose={() => setShowBlockedEditorModal(false)} />
      <DeleteClipModal open={showDeleteModal} setOpen={setShowDeleteModal} onDeleteClip={handleDeleteClip} />
      <div className={`video-item ${triggerGlowAnimation && 'video-item-glow'}`} style={styles.videoItem}>
        {/* Can be used in case we need to overlay the whole component with onClick event */}
        {withOverlay && <div style={styles.overlay} />}
        <div
          style={{
            display: 'flex',
            background: COLORS.GRAY_CONTROLS,
            justifyContent: 'space-between',
            borderRadius: '8px 8px 0 0'
          }}
        >
          <div style={styles.videoTopInfo}>
            <div style={{ display: 'flex', alignItems: 'center', maxWidth: isUploadsTab ? '500px' : 'unset' }}>
              {isUploadsTab || isTwitchPage ? <Typography style={{ ...styles.videoTime, maxWidth: 190 }} className="truncated-text">{clip.name}</Typography> :

                <>
                  {!isTwitchPage && !isTwitchClip &&
                    <>
                      <Typography className='cursor-pointer' onClick={copyTimestamp} style={styles.videoTime}>{secondsToTime(clip.start_time)} - {secondsToTime(clip.end_time)}</Typography>
                      <div style={{ marginRight: 15 }} />
                      <Image src={IconCopy} preview={false} className="cursor-pointer" width={20} onClick={copyTimestamp} />
                      <div style={styles.smallVerticalDivider} />
                    </>
                  }
                  <Typography style={styles.videoLengthText}>{formatSeconds(duration, !isInt(duration))}</Typography>
                </>
              }

              {(isTwitchPage || isUploadsTab) &&
                <>
                  {/* <div style={{ marginRight: 15 }} />
                  <Image src={IconCopy} preview={false} className="cursor-pointer" width={20} onClick={copySourceURL} /> */}
                  <div style={styles.smallVerticalDivider} />
                  <Typography style={styles.videoTime}>{dayjs(clip.createdAt).format('MMMM DD, YYYY')}</Typography>
                  <div style={styles.smallVerticalDivider} />
                  <Typography style={styles.videoLengthText}>{formatSeconds(duration, !isInt(duration))}</Typography>
                </>
              }

            </div>
            {!isUploadsTab && !isTwitchPage && !isTwitchClip &&
              <div>
                <SpikeScore score={clip.clip_ranking} short isYoutubeClip={isYoutubeCard} />
              </div>
            }

          </div>
          {!isTwitchPage &&
            <>
              <div>
                <div
                  onClick={handleConfirmDeletion}
                  style={{
                    ...styles.lightningButton,
                    height: '100%',
                    cursor: 'pointer',
                  }}
                >
                  <DeleteOutlined style={{ fontSize: 26 }} />
                </div>
              </div>
              <div>
                <div
                  id='edit-button'
                  onClick={() => editClicked(false)}
                  className={disabled ? '' : 'primary-hover'}
                  style={styles.editButton}
                >
                  {/* <Image src={IconEdit} preview={false} /> */}
                  Edit
                </div>
              </div>
            </>
          }

          {isTwitchPage && clip.platform_views_count &&
            <div>
              <div
                style={{
                  ...styles.viewsButton,
                  height: '100%',
                }}
              >
                <Typography style={styles.viewsText}>
                  {clip.platform_views_count}
                </Typography>
                <Image src={IconEye} preview={false} />
              </div>
            </div>
          }

        </div>
        <div>
          <div
            style={styles.playerContainer}
          >
            {clip.status !== 'in progress' && (getLoadingClipId() !== clip?.id) &&
              <div className={`cursor-pointer ${isTwitchPage ? 'twitch-player' : ''}`} onClick={() => (!enablePreview ? editClicked(true) : null)} style={{ position: 'relative' }}>
                <div style={styles.clipType}>
                  <div style={styles.clipTypeItem}>{formatClipType()}</div>
                </div>
                {clip.mobile_download_url ?
                  <Player
                    ref={playerRef}
                    url={clip.mobile_download_url!}
                    thumbnailUrl={clip.mobile_thumbnail_url || clip.thumbnail_url || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACoCAMAAABt9SM9AAAAA1BMVEUAAACnej3aAAAAR0lEQVR4nO3BAQEAAACCIP+vbkhAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAO8GxYgAAb0jQ/cAAAAASUVORK5CYII='}
                    isVisible={isVisible} />
                  :
                  <Player
                    ref={playerRef}
                    url={clip.desktop_download_url || clip.url}
                    thumbnailUrl={clip.thumbnail_url || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACoCAMAAABt9SM9AAAAA1BMVEUAAACnej3aAAAAR0lEQVR4nO3BAQEAAACCIP+vbkhAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAO8GxYgAAb0jQ/cAAAAASUVORK5CYII='}
                    playingVideoScope={[clip.start_time_in_event == null ? null : clip.start_time_in_event, clip.end_time_in_event == null ? null : clip.end_time_in_event]}
                    isVisible={isVisible}
                    height={280}
                  />
                }

              </div>}

            {(clip.status == 'in progress') &&
              <div style={styles.loaderContainer}>
                <div ref={lottieRef} style={{ width: 70, height: 70 }}></div>
                <Typography style={{ marginTop: 10, fontWeight: 600, fontSize: 12,textAlign: 'center' }}>{LOADER_TEXT[loaderTextId]}</Typography>
              </div>
            }
            {getLoadingClipId() === clip?.id &&
              <div style={styles.loaderContainer}>
                <div ref={lottieRef} style={{ width: 70, height: 70 }}></div>
                <Typography style={{ marginTop: 10, fontWeight: 600, fontSize: 12, textAlign: 'center' }}>{LOADER_TEXT[loaderTextId]}</Typography>
              </div>
            }
          </div>
        </div>
        <div style={styles.bottomControllsBox}>
          <div id='mobile-download-url-button' style={{ ...styles.downloadBox, borderRight: '1px solid #222' }}>
            <Image className={disabled ? '' : 'cursor-pointer'} onClick={() => {
              if (!disabled) {
                if (clip.mobile_download_url) {
                  download(true);
                } else {
                  editClicked(false);
                }
              }
            }} src={IconDownload} preview={false} />
            <Typography style={styles.downloadDevice}>Mobile Resolution</Typography>
            <div
              onClick={() => {
                if (clip.mobile_download_url) {
                  download(true);
                } else {
                  editClicked(false);
                }
              }}
              className="primary-hover"
              style={{
                ...styles.downloadQualityBox,
                ...(disabled && styles.downloadQualityBoxDisabled),
                ...(disabled && styles.disabledClick),
              }}
            >
              <Typography
                style={{
                  ...styles.downloadQualityText,
                  ...(disabled && styles.downloadQualityTextDisabled),
                }}
              >
                720
              </Typography>
            </div>
            <div
              style={{
                ...styles.downloadQualityBox,
                ...(((!isHdAvailable) || disabled) &&
                  styles.downloadQualityBoxDisabled),
              }}
              className={((!isHdAvailable) || disabled) ? 'secondary-hover' : 'primary-hover'}
              onClick={!isTwitchPage ? () => {
                if ((!isHdAvailable)) {
                  navigate('/subscriptions');
                } else {
                  if (clip.mobile_download_url) {
                    download(true, true);
                  } else {
                    editClicked(false);
                  }
                }
              } : () => null}
            >
              {(!isHdAvailable) && <Image style={styles.orangeStar} src={IconOrangeStar} preview={false} />}

              <Typography
                style={{
                  ...styles.downloadQualityText,
                  ...(((!isHdAvailable) || disabled) &&
                    styles.downloadQualityTextDisabled),
                  ...((!USER_NOT_SUBSCRIBED && disabled) &&
                    styles.disabledClick),
                }}
              >
                HD
              </Typography>
            </div>
          </div>

          <div id='desktop-download-url-button' style={styles.downloadBox}>
            <Image className={disabled ? '' : 'cursor-pointer'} onClick={() => disabled ? null : download()} src={IconDownload} preview={false} />
            <Typography style={styles.downloadDevice}>Desktop Resolution</Typography>
            <div
              onClick={() => download()}
              className="primary-hover"
              style={{
                ...styles.downloadQualityBox,
                ...(disabled && styles.downloadQualityBoxDisabled),
                ...(disabled && styles.disabledClick),
              }}
            >
              <Typography
                style={{
                  ...styles.downloadQualityText,
                  ...(disabled && styles.downloadQualityTextDisabled),
                }}
              >
                720
              </Typography>
            </div>
            <div
              onClick={!isTwitchPage ? () => (USER_NOT_SUBSCRIBED && !isHdAvailable) ? navigate('/subscriptions') : download(false, true) : () => null}
              className={((USER_NOT_SUBSCRIBED && !isHdAvailable) || disabled) ? 'secondary-hover' : 'primary-hover'}
              style={{
                ...styles.downloadQualityBox,
                ...(((USER_NOT_SUBSCRIBED && !isHdAvailable) || disabled) &&
                  styles.downloadQualityBoxDisabled),
                ...((!USER_NOT_SUBSCRIBED && disabled) &&
                  styles.disabledClick),
              }}
            >
              {(USER_NOT_SUBSCRIBED && !isHdAvailable) && <Image style={styles.orangeStar} src={IconOrangeStar} preview={false} />}
              <Typography
                style={{
                  ...styles.downloadQualityText,
                  ...(((USER_NOT_SUBSCRIBED && !isHdAvailable) || isTwitchPage) && styles.downloadQualityTextDisabled),
                }}
              >
                HD
              </Typography>
            </div>
          </div>

          {isTwitchPage &&
            <div style={styles.qrCodeContainer}>
              <Image preview={false} src={BluredQRCode} />
            </div>
          }

          {!isTwitchPage &&
            <>
              <div onClick={() => setOpenLargeQRCode(true)} style={styles.qrCodeContainer}>
                {/* <QRCode
                  style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                  value={
                    clip.mobile_download_url ||
                    clip.desktop_download_url ||
                    clip.download_url
                  }
                /> */}
                <Tooltip placement='bottom' title='Click to see the QR code'>
                  <Image preview={false} src={BluredQRCode} />
                </Tooltip>
              </div>
              {openLargeQRCode &&
                <div style={styles.largeQRCodeContainer}>
                  <div style={styles.socialIconsContainer}>
                    <TwitterShareButton
                      url={downloadUrl}
                    >
                      <Image
                        src={IconShareTwitter}
                        preview={false}
                        width={28}
                        className="cursor-pointer"
                      />
                    </TwitterShareButton>
                    <EmailShareButton url={downloadUrl}>
                      <Image
                        src={IconShareGmail}
                        preview={false}
                        width={28}
                        className="cursor-pointer"
                      />
                    </EmailShareButton>

                    <FacebookShareButton url={downloadUrl}>
                      <Image
                        src={IconShareFacebook}
                        preview={false}
                        width={28}
                        className="cursor-pointer"
                      />
                    </FacebookShareButton>

                    <TelegramShareButton url={downloadUrl}>
                      <Image
                        src={IconShareTelegram}
                        preview={false}
                        width={28}
                        className="cursor-pointer"
                      />
                    </TelegramShareButton>

                    <WhatsappShareButton url={downloadUrl}>
                      <Image
                        src={IconShareWhatsapp}
                        preview={false}
                        width={28}
                        className="cursor-pointer"
                      />
                    </WhatsappShareButton>
                    <div style={styles.copyIconContainer}>
                      <Image src={IconCopy} preview={false} className="cursor-pointer" width={24} onClick={copyUrl} />
                    </div>

                  </div>
                  <div onClick={() => setOpenLargeQRCode(false)} style={styles.largeQRCode}>
                    <QRCode
                      style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                      value={
                        clip.mobile_download_url ||
                        clip.desktop_download_url ||
                        clip.download_url
                      }
                    />
                  </div>
                </div>
              }
            </>
          }
        </div>
      </div>
      {
        isYoutubeCard && (getHashtags() || clip?.description) &&
        <div style={styles.hashtagContainer}>

          {clip?.headline && <div style={{ display: 'flex', alignItems: 'center', marginTop: 7 }}>
            <Typography style={styles.hashtagsHeading}>Headline:&nbsp;<Typography style={styles.hashtagsText}>{clip?.headline}</Typography><IconCopySVG onClick={copyHeadline} className="cursor-pointer" width={16} height={16} style={{ marginLeft: 8 }} /></Typography>
          </div>
          }
          {getHashtags() && <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={styles.hashtagsHeading}>Hashtags:&nbsp;<Typography style={styles.hashtagsText}>{getHashtags().join(' ')}</Typography> <IconCopySVG onClick={copyHashtags} className="cursor-pointer" width={16} height={16} style={{ marginLeft: 8 }} /></Typography>

          </div>
          }
          {clip?.description && <div style={{ display: 'flex', alignItems: 'center', marginTop: 7 }}>
            <Typography style={styles.hashtagsHeading}>Description:&nbsp;<Typography style={styles.hashtagsText}>{clip?.description}</Typography><IconCopySVG onClick={copyDescription} className="cursor-pointer" width={16} height={16} style={{ marginLeft: 8 }} /></Typography>
          </div>
          }
        </div>
      }

    </div>
  );
});

SpikeCardNew.displayName = 'SpikeCardNew';

export default SpikeCardNew;
