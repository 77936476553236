import { useState, useMemo, useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { UserContext } from '../../data/userContext';
import axios from 'axios';
import {
  Col,
  Row,
  Input,
  Pagination,
  message,
  Modal,
  Image,
  Typography,
} from 'antd';
import { IClip } from '../../data/intefaces/stream.interface';
import { COLORS } from '../../themes/colors';
import IconClose from '../../assets/modal-close.svg';
import Loader from '../../assets/loader.gif';
import EmptyTwitchClips from '../../assets/empty_search_twitch_clips.png';
import { SpikeCardNew } from '../stream-card/spike-card-new';

export const SearchTwitchClips = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const usernameFromURL = searchParams.get('username');

  const [open, setOpen] = useState<boolean>(true);
  const [twitch_username, setTwitchusername] = useState<string>('');
  const [clips, setClips] = useState<IClip[]>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showEmptyImage, setshowEmptyImage] = useState<boolean>(true);
  const [showClips, setShowClips] = useState<boolean>(true);
  const userContext = useContext(UserContext);

  const PAGE_SIZE = 6;
  const indexOfLastItem = currentPage * PAGE_SIZE;
  const indexOfFirstItem = indexOfLastItem - PAGE_SIZE;

  useEffect(() => {
    const fetchData = async () => {
      if (usernameFromURL) {
        setshowEmptyImage(false);
        setShowClips(false);
        setLoading(true);
        const regex = /^[a-zA-Z0-9_]+$/;

        if (!regex.test(usernameFromURL)) {
          messageApi.error('Invalid Twitch user name', 5);
          setTwitchusername('');
          setLoading(false);
          return;
        }

        const url = `/streamer/search-twitch-clips?username=${usernameFromURL}`;

        const response = await axios.get(url).catch((error) => {
          if (error.response) {
            console.log(error.response);
          }
        });

        setLoading(false);
        setShowClips(true);

        if (response?.data.length == 0) {
          messageApi.error('No Twitch Clips were found for this user', 3);
        } else {
          setClips(response?.data);
        }
      };
    };
    fetchData();
  }, [usernameFromURL]);


  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const styles = useMemo(() => {
    return {
      main: {
        background: 'rgb(72, 71, 71)',
        fontSize: 16,
        height: 56,
        padding: '0 25px',
        borderRadius: '8px 0 0 8px',
        width: 500
      },
      button: {
        letterSpacing: 'normal',
        left: 5,
        top: 0,
        height: 35,
        width: 100,
        fontSize: 14,
        fontFamily: 'BrandonText',
      },
      uploadButton: {
        letterSpacing: 'normal',
        left: 5,
        height: 34,
        width: 280,
        fontSize: 12,
      },
      searchButton: {
        letterSpacing: 'normal',
        left: 15,
        height: 34,
        width: 180,
        fontSize: 12,
      },
      makeClipsButtonStyle: {
        cursor: 'pointer',
        backgroundColor: COLORS.PRIMARY,
        borderRadius: 6,
        height: 56,
        width: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 14px',
      },
      makeClipsText: {
        fontSize: '20px',
        fontWeight: 400,
        color: COLORS.BACKGROUND_DARK_GRAY,
        marginLeft: '12px',
      },
    } as const;
  }, []);

  const onClick = async () => {
    setshowEmptyImage(false);
    setShowClips(false);
    setLoading(true);
    const regex = /^[a-zA-Z0-9_]+$/;

    if (!regex.test(twitch_username)) {
      messageApi.error('Invalid Twitch user name', 5);
      setTwitchusername('');
      setLoading(false);
      return;
    }

    const url = `/streamer/search-twitch-clips?username=${twitch_username}`;

    const response = await axios.get(url).catch((error) => {
      if (error.response) {
        console.log(error.response);
      }
    });

    setLoading(false);
    setShowClips(true);

    if (response?.data.length == 0) {
      messageApi.error('No Twitch Clips were found for this user', 3);
    } else {
      setClips(response?.data);
    }
  };

  const selectClip = async (
    event: React.MouseEvent<HTMLDivElement>,
    clip: IClip,
  ) => {
    // remove extra params for urls
    let trimmedUrl = clip.url;
    const index = clip.url.indexOf('?');
    if (index >= 0) {
      trimmedUrl = clip.url.substring(0, index);
    }

    const response = await axios
      .post('/streamer/upload', { platform_url: clip.url, platform: 'twitch', duration: clip.duration, title: clip.name, upload_mode: '' })
      .catch((error) => {
        if (error.response) {
          console.error(error?.response?.data);
        }
      });

    switch (response?.data) {
      case 'exists': {
        messageApi.error('Twitch clip already exists', 5);
        break;
      }
      case 'not found': {
        messageApi.error('Twitch clip was not found', 5);
        break;
      }
      case 'max reached': {
        navigate('/subscriptions', { state: { message: 'Uh-oh! You\'ve hit the maximum upload minutes limit.' } });
        break;
      }
      default: {
        messageApi.success('Clip uploading...', 5);
        userContext?.setUser(response?.data);
        break;

      }
    }

    setOpen(false);
    setTimeout(() => {
      navigate('/spikes');
    }, 6000);
  };

  const onClose = () => {
    setTimeout(() => {
      navigate('/spikes');
    }, 300);
  };

  return (
    <Modal
      open={open}
      footer={null}
      bodyStyle={{ background: '#2D2D2D', padding: 40 }}
      style={{
        borderRadius: 24,
        overflow: 'hidden',
        height: 'auto',
        minHeight: 700,
        background: '#2D2D2D',
      }}
      closable={false}
      maskClosable={false}
      width={2070}
      centered
    >
      {contextHolder}
      <Row gutter={24}>
        <Col
          span={24}
          className="flex-row space-between w-100"
          style={{ paddingRight: 20 }}
        >
          <div style={{ position: 'absolute', top: -13, right: 3 }} className='cursor-pointer' onClick={onClose}>
            <Image src={IconClose} preview={false} width={24} />
          </div>
          {!usernameFromURL &&
            <div style={{ display: 'flex' }}>
              <Input
                placeholder="Enter Twitch Username"
                value={twitch_username}
                style={styles.main}
                onChange={(e) => setTwitchusername(e.target.value)}
              />
              <div onClick={onClick} style={styles.makeClipsButtonStyle}>
                <Typography style={styles.makeClipsText}>Search</Typography>
              </div>
            </div>
          }
        </Col>
      </Row>
      <Row gutter={[54, 24]}>
        {isLoading && (
          <Col span={24} style={{
            top: 270,
            display: 'flex',
            justifyContent: 'center',
            height: '100%'
          }}>
            <Image
              src={Loader}
              preview={false}
              width={70}
              style={{ borderRadius: 8 }}
            />
          </Col>
        )}
        {showEmptyImage && (
          <Col style={{ display: 'flex', marginTop: 70, justifyContent: 'center' }} span={24}>
            <Image
              src={EmptyTwitchClips}
              preview={false}
            />
          </Col>
        )}
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '32px', maxWidth: '2020px', margin: '0 auto', justifyContent: clips?.slice(indexOfFirstItem, indexOfLastItem).length > 2 ? 'center' : 'flex-start', padding: '50px 30px 20px 15px' }}>
          {showClips &&
            clips
              ?.slice(indexOfFirstItem, indexOfLastItem)
              .map((clip: any, i: number) => (
                <div key={i} className="selected-spike-card" onClick={(e) => selectClip(e, clip)}>
                  <SpikeCardNew
                    clip={clip}
                    platformStreamId={
                      clip.stream ? clip.stream.platform_stream_id : null
                    }
                    platformUrl={clip?.platform_url || clip?.url || null}
                    isVisible
                    enableShare={false}
                    enableEdit={false}
                    isTwitchCard
                    enablePreview
                    isTwitchPage
                    isUploadsTab
                  />
                </div>

              ))}
        </div>
      </Row>
      {showClips && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination
            current={currentPage}
            pageSize={PAGE_SIZE}
            total={clips?.length}
            onChange={handlePageChange}
            simple={true}
            hideOnSinglePage={true}
            showQuickJumper={false}
            style={{ marginTop: 30 }}
          />
        </div>
      )}
    </Modal>
  );
};

export default SearchTwitchClips;
