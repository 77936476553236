import { Col, Modal, Row, Space, Checkbox, Button, Typography, Image } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { UserContext } from '../../data/userContext';
import { useNavigate } from 'react-router';
import YTGif from '../../assets/yt-error-tutorial.gif';
import IconClose from '../../assets/modal-close.svg';
import './ErrorInfoModal.less';

export type PlatformType = 'personal' | 'youtube' | 'google_drive';

export interface IErrorInfoModalProps {
  open: boolean;
  onClose: () => void;
  platform: PlatformType;
  customTitle?: string;
  withoutDescription?: boolean;
}

const CONTENT = {
  youtube: {
    title: 'YouTube failed to process',
    description: 'YouTube currently doesn’t allow you to download this video.',
    steps: [
      '1. Log in to YouTube Studio.',
      '2. Navigate to ‘Videos’.',
      '3. Download the video & upload it to Spikes Studio.',
      '(Trying again later might work as well)',
    ],
    footer: '*Please note that you should only use content that is either your own or has been explicitly authorized for use.',
  },
  personal: {
    title: 'Computer upload failed to process',
    description: 'Your upload failed to process',
    steps: [
      '1. Refund your credits.',
      '2. Try to re-upload the file, this will usually work.',
    ],
    footer: null
  },
  google_drive: {
    title: 'Google Drive upload failed to process',
    description: 'Your upload failed to process',
    steps: [
      '1. Refund your credits.',
      '2. Make sure your link is public.',
      '3. Try to re-upload the file, this will usually work.',
    ],
    footer: null
  },
}

export const ErrorInfoModal = ({ open, onClose, platform = 'youtube', customTitle, withoutDescription }: IErrorInfoModalProps) => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const content = CONTENT[platform];

  return (
    <Modal
      open={open}
      className='error-info-modal'
      footer={null}
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      onCancel={onClose}
      closable={false}
      maskClosable={false}
      width={395}
      centered
    >
      <div className="error-info-modal__container">
        <div className="error-info-modal__header">
          <div className='error-info-modal__header-title'>{customTitle || content?.title}</div>
          <div className='right'>
            <a className="error-info-modal__header-learn-more" href='https://intercom.help/spikes-studio/en/' target='_blank' rel="noreferrer">Learn More</a>
            <div className='cursor-pointer' onClick={onClose}>
              <Image src={IconClose} preview={false} width={16} />
            </div>
          </div>
        </div>

        {platform === 'youtube' && (
          <div className="error-info-modal__gif">
            <Image src={YTGif} alt='GIF' />
          </div>
        )}

        <div className="error-info-modal__content">
          {!withoutDescription &&
            <div className="error-info-modal__content-title">
              {content?.description}
            </div>
          }
          <div className='error-info-modal__content-block'>
            {content?.steps.map((step, index) => (
              <div key={index} className="error-info-modal__content-description">{step}</div>
            ))}
          </div>

          {content?.footer && (
            <div className="error-info-modal__content-footer">{content?.footer}</div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ErrorInfoModal;