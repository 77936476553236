import { Col, Modal, Row, Space, Button} from 'antd';
import {  useMemo, useState } from 'react';
import { COLORS } from '../../themes/colors';
import Title from 'antd/lib/typography/Title';
import { useNavigate } from 'react-router';

export interface IGenericModalProps {
    title : string,
    url : string
}


export const GenericModal = ({title,url='/'} : IGenericModalProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(true);
  
  const styles = useMemo(() => {
    return {
      modalStyle: {
        borderRadius: 24,
        overflow: 'hidden',
        border: '1px solid #FFF',
        borderColor: COLORS.PRIMARY,
      },
      modalBody: {
        background: '#001922',
        padding: 40,
      },
      checkboxStyle : {
        fontSize: 18,
        marginTop : 10,
        marginLeft : 0
      }
    } as const;
  }, []);

  const onClose = () => {
    setOpen(false);
    setTimeout(() => {
      navigate(url);
    }, 300);
  };

  return (
    <Modal
      open={open}
      footer={null}
      bodyStyle={styles.modalBody}
      style={styles.modalStyle}
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      closable={false}
      maskClosable={false}
      width={600}
      centered
    >
      <Row gutter={24}>
        <Col span={24} className='flex-row space-between w-100' >
          <div>
            <Title level={3} style={{ marginTop: 20, fontFamily: 'Poster', textAlign: 'center' }}>{title}</Title>
          </div>
          <Space align="center">
            <Button type="primary" size='middle' onClick={onClose} style={{ marginTop: 30 }}>OK</Button>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default GenericModal;