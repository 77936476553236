export const noop = (args?: any) => {
  return;
};

export const sanitizeStrings = (toSanitize: any): any => {
  if (typeof toSanitize === 'string') {
    return toSanitize.replace(/,/g, ' ');
  } else if (toSanitize && typeof toSanitize === 'object') {
    return Object.keys(toSanitize).reduce(
      (acc, key) => ({
        ...acc,
        [key]: sanitizeStrings(toSanitize[key]),
      }),
      {},
    );
  }
};

export const secondsToTime = (duration: number) => {
  const h = Math.floor(duration / 3600).toString().padStart(2,'0'),
    m = Math.floor(duration % 3600 / 60).toString().padStart(2,'0'),
    s = Math.floor(duration % 60).toString().padStart(2,'0');
  
  return `${h}:${m}:${s}`;
};

export const formatSeconds = (value: number, milliseconds = false) => {
  const date = new Date(0);
  date.setSeconds(value);
  return `${date.toISOString().substring(14, 19)}${milliseconds ? `.${String(value % 1 * 100).padStart(2, '0')}` : ''}`;
};

export const isInt = (n: number) => {
  return Number(n) === n && n % 1 === 0;
};

export function formatSecondsWithMilliseconds(seconds: number): string {
  const minutes = Math.floor(seconds / 60); // Get the full minutes
  const remainingSeconds = Math.floor(seconds % 60); // Get the remaining seconds
  const milliseconds = Math.round((seconds % 1) * 100); // Get the milliseconds

  // Format the minutes, seconds, and milliseconds to always have two digits
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');
  const formattedMilliseconds = String(milliseconds).padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds}`;
}