import './CancelSubscriptionModal.less';
import { ReactComponent as IconPlay } from '../../assets/onboarding_play.svg';
import { ReactComponent as IconRightArrow } from '../../assets/onboarding_right_arrow.svg';
import ImageClosedGift from '../../assets/closed_gift.png';
import ImageOpenedGift from '../../assets/opened_gift.png';
import { CancelSubSteps } from './CancelSubscriptionModal';
import { leaveReasonList } from './LeaveReason';
import { useContext, useState } from 'react';
import axios from 'axios';
import { message } from 'antd';
import { UserContext } from '../../data/userContext';

interface ISuggestGiftProps {
  chosenReason: string | null;
  handleCloseModal: () => void;
  handleProceedCancellation: () => void;
}

export const SuggestGift = ({ chosenReason, handleCloseModal, handleProceedCancellation }: ISuggestGiftProps) => {
  const [offerClaimed, setOfferClaimed] = useState(false);
  const [isLoadingOffer, setIsLoadingOffer] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const userContext = useContext(UserContext);
  const description = leaveReasonList.find(item => item.value === chosenReason)?.description || leaveReasonList.find(item => item.value === 'other')?.description;

  const handleClaimOffer = async () => {
    if (isLoadingOffer) return;

    setIsLoadingOffer(true);
    const response = await axios.put('/streamer/subscription');

    if (response?.data?.subscriptions) {
      userContext?.setUser(response?.data);
      setOfferClaimed(true);
    } else {
      messageApi.error('Failed to generate discount. Please try again');
    }
    setIsLoadingOffer(false);
  }

  const handleContinue = () => {
    handleCloseModal();
  }

  return (
    <div className='suggest-gift'>
      {contextHolder}
      {offerClaimed ?
        <img src={ImageOpenedGift} />
        :
        <img src={ImageClosedGift} />
      }
      <div className='suggest-gift__label'>
        {offerClaimed ?
          <span>Enjoy your <span className='bold'>50% discount</span> for the next 2 months. If you need help, we&apos;re here for you.</span>
          :
          <span>We&apos;d love to make Spikes Studio Pro work for you. <span className='bold'>Enjoy 50% off</span> your next 2 months on us!</span>
        }
      </div>

      {offerClaimed ?
        <div className="suggest-gift__subtitle">Take advantage of all the powerful features Spikes Studio has to offer at a reduced price.</div>
        :
        <>{description && <div className="suggest-gift__subtitle">{description}</div>}</>
      }
      <div className="suggest-gift__buttons">
        {offerClaimed
          ?
          <div onClick={handleContinue} className="suggest-gift__button">
            Continue
          </div>
          :
          <>
            <div onClick={handleClaimOffer} className="suggest-gift__button secondary">
              {isLoadingOffer ? 'Loading...' : 'Claim offer'}
            </div>
            <div onClick={isLoadingOffer ? () => null : handleProceedCancellation} className="suggest-gift__button">
              Proceed cancellation
            </div>
          </>
        }

      </div>
    </div>
  )
}