import { useContext, useMemo, useState } from 'react';
import Space from 'antd/lib/space';
import { Avatar, Badge, Image, Popover, Typography } from 'antd';

import Title from 'antd/lib/typography/Title';
import { UserContext } from '../../data/userContext';
import { Link } from 'react-router-dom';

import Logo from '../../assets/spikes-logo.svg';
import IconLogout from '../../assets/icon-menu-logout.svg';
import IconSettings from '../../assets/icon-menu-settings.svg';
import {
  UsergroupAddOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { InviteModal } from '../invite-modal';
import { SettingsModal } from '../settings-modal';

export interface IHeaderProps {
  title: string;
}

export const Header = ({ title }: IHeaderProps) => {
  const userContext = useContext(UserContext);
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  //title = 'EXPLORE YOUR TOP-RATED MOMENTS';

  const styles = useMemo(() => {
    return {
      container: {
        paddingTop: 20,
        width: '100%',
      },
      hello: {
        fontFamily: 'Poppins',
        fontSize: 36,
        marginBottom: 0,
      },
      loggedInTitle: {
        fontWeight: 200,
      },
      login: {
        fontFamily: 'Poppins',
        fontSize: 28,
        marginBottom: 0,
        fontWeight: 400,
        marginTop: 30,
      },
      avaterContainer: {
        width: 162,
        textAlign: 'right',
        position: 'relative',
      },
      avaterBorder: {
        border: '1px solid #67FFCC',
        width: 57,
        height: 57,
        borderRadius: 12,
        position: 'absolute',
        right: -5,
        top: 5,
        filter: 'drop-shadow(0px 0px 8px #67FFCC)',
      },
      avatar: {
        backgroundColor: '#FF2C90',
        verticalAlign: 'middle',
        borderRadius: 8,
        fontFamily: 'Poppins',
        fontSize: 24,
      },
      badge: {
        position: 'absolute',
        top: -26,
        right: -17,
        transform: 'scale(2)',
      },
      menuOverlayInnerStyle: {
        background: 'rgba(35, 38, 43, 0.8)',
        border: 0,
        borderRadius: 22,
        padding: '20px 25px',
        backdropFilter: 'blur(4px)',
        width: 250,
        marginTop: 10,
      },
      menuItemText: {
        color: 'rgba(244, 244, 244, 0.8)',
        fontSize: 18,
        fontWeight: 500,
        marginTop: 3,
      },
    } as const;
  }, []);

  const logout = () => {
    localStorage.removeItem('user');
    window.location.href = '/';
  };

  const content = (
    <>
      <Space
        className="uppercase cursor-pointer display-flex"
        size="middle"
        onClick={() => setShowInviteModal(true)}
      >
        <UsergroupAddOutlined style={styles.menuItemText} />
        <Typography style={styles.menuItemText}>Invite Friends</Typography>
      </Space>
      <Space
        className="uppercase cursor-pointer display-flex"
        size="middle"
        onClick={() => setShowSettingsModal(true)}
      >
        <Image src={IconSettings} preview={false} width={20} />
        <Typography style={styles.menuItemText}>Settings</Typography>
      </Space>
      <Space className="uppercase cursor-pointer display-flex" size="middle">
        <QuestionCircleOutlined style={styles.menuItemText} />
        <a
          href="https://spikesgghelp.zendesk.com/"
          target="_blank"
          rel="noreferrer"
          style={styles.menuItemText}
        >
          FAQ
        </a>
      </Space>
      <Space
        className="uppercase cursor-pointer display-flex"
        size="middle"
        onClick={logout}
      >
        <Image src={IconLogout} preview={false} width={20} />
        <Typography style={styles.menuItemText}>Logout</Typography>
      </Space>
    </>
  );

  return (
    <div style={styles.container} className="space-between align-initial">
      <Link to="/">
        <Image src={Logo} preview={false} height={36} />
      </Link>
      <>
        <Space direction="vertical" align="center">
          {userContext?.user ? (
            <>
              <Title style={styles.hello}>
                {userContext?.user?.username}&apos;s zone
              </Title>
              <Title level={3} style={styles.loggedInTitle}>
                Discover your top-rated Spikes below.
              </Title>
            </>
          ) : (
            <Title style={styles.login}>{title}</Title>
          )}
        </Space>
      </>
      <div style={styles.avaterContainer}>
        {userContext?.user && (
          <>
            <Avatar
              shape="square"
              size={42}
              style={{
                marginRight: 20,
                background: 'transparent',
                border: '1px solid #FFFFFF44',
                borderRadius: 8,
              }}
              className="cursor-pointer"
              onClick={() => setShowInviteModal(true)}
            >
              <UsergroupAddOutlined style={{ fontSize: 22 }} />
            </Avatar>
            <Popover
              content={content}
              trigger="hover"
              placement="bottomRight"
              overlayInnerStyle={styles.menuOverlayInnerStyle}
            >
              <div style={styles.avaterBorder}></div>
              <Avatar
                shape="square"
                size={46}
                style={styles.avatar}
                className="cursor-pointer"
              >
                {userContext?.user && userContext?.user?.username ? userContext?.user?.username.charAt(0) : 'U'}
              </Avatar>
              <Badge color="#05F29B" style={styles.badge} />
            </Popover>
          </>
        )}
      </div>

      {showSettingsModal && (
        <SettingsModal onClose={() => setShowSettingsModal(false)} />
      )}

      {showInviteModal && (
        <InviteModal onClose={() => setShowInviteModal(false)} />
      )}
    </div>
  );
};

export default Header;
