import React, { useContext, useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../../themes/colors';
import { ReactComponent as CheckCircle } from '../../../assets/check-circle.svg';
import { useNavigate } from 'react-router';
import './GetApiCreditsCard.less';
import { Typography } from 'antd';
import { Slider } from 'antd';
import { UserContext } from '../../../data/userContext';
import { checkUserIsIndian, roundWithOneDecimal } from '../../../utils';
import { LoginModal } from '../../../components/login-modal';
import { StripeGetMinutesButton } from '../../../components/stripe-get-minutes-button';
import { StripeGetApiCreditsButton } from '../../../components/stripe-get-api-credits-button/StripeGetApiCreditsButton';

type StepToPriceAndMinutes = {
  [key: number]: { price: number; credits: number; approxMins: number };
};

const stepToPriceAndCredits: StepToPriceAndMinutes = {
  1: { price: 50, credits: 3000, approxMins: 300 },
  2: { price: 100, credits: 6000, approxMins: 600 },
  3: { price: 150, credits: 9000, approxMins: 900 },
  4: { price: 300, credits: 20000, approxMins: 2000 },
  5: { price: 600, credits: 40000, approxMins: 4000 },
  6: { price: 800, credits: 60000, approxMins: 6000 },
  7: { price: 1200, credits: 100000, approxMins: 10000 },
  8: { price: 1500, credits: 140000, approxMins: 14000 },
};
// const stepToPriceAndMinutes: StepToPriceAndMinutes = {
//   1: { price: 10, credits: 100 },
//   2: { price: 20, credits: 200 },
//   3: { price: 30, credits: 300 },
//   4: { price: 40, credits: 400 },
//   5: { price: 50, credits: 500 },
//   6: { price: 60, credits: 600 },
//   7: { price: 70, credits: 700 },
//   8: { price: 80, credits: 800 },
//   9: { price: 90, credits: 900 },
//   10: { price: 100, credits: 1000 },
//   11: { price: 110, credits: 1100 }, // Adjust this as needed
// };

const GetApiCreditsCard = () => {
  const [creditsValue, setCreditsValue] = useState<number>(1); // Starting at step 1
  const [totalPrice, setTotalPrice] = useState<number>(10); // Starting price
  const [approxMinutes, setApproxMinutes] = useState<number>(2000);
  const [totalCredits, setTotalCredits] = useState<number>(100); // Starting minutes
  const userContext = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    const { price, credits, approxMins } = stepToPriceAndCredits[creditsValue];
    setTotalPrice(price);
    setTotalCredits(credits);
    setApproxMinutes(approxMins);
  }, [creditsValue]);

  const styles = useMemo(() => {
    return {
      container: {
        maxWidth: '382px',
        width: '100%',
        height: '476px',
        flexShrink: 0,
        borderRadius: '8px',
        border: '0px solid #6D6D6D',
        background: 'var(--base)',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '0 0 20px 0',
      },
      topSection: {
        display: 'flex',
        height: '68px',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        background: 'var(--primary-gradient)',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        marginBottom: '40px'
      },
      topText: {
        color: 'var(--text-on-color)',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '36.1px', // 150.417%
      },
      priceRow: {
        color: 'var(--text-subdued)',
        fontFamily: 'Poppins',
        fontSize: '48px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '56.6px', // Adjusted line height
        alignItems: 'center', // Start from the top
        textAlign: 'center', // Center text horizontally
      },
      uploadMinutesRow: {
        color: 'var(--text-base)',
        fontFamily: 'Poppins',
        fontSize: '32px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '48px', // Adjusted line height
        alignItems: 'center', // Start from the top
        textAlign: 'center', // Center text horizontally
        marginTop: '20px', // Added margin-top
      },
      trackStyle: {
        backgroundColor: '#FFFE58 !important',
        height: 16,
        marginTop: -7,
        marginLeft: -7,
        borderRadius: '8px',
      },
      explanationsText: {
        color: 'var(--text-subdued-2)',
        fontSize: 12,
        fontFamily: 'Poppins',
        textAlign: 'center',
        marginTop: 'auto'
      },
      approxMinutesRow: {
        margin: '0 auto',
        color: 'var(--text-subdued)',
        marginBottom: '-10px',
        marginTop: '-3px'
      }

    } as const;
  }, []);

  return (
    <div style={styles.container} className={'pro-tier subscription-card'}>
      <div style={styles.topSection}>
        <span style={styles.topText}>Get API Credits</span>
      </div>

      {/* First Row */}
      <div style={styles.priceRow}>
        <span>
          ${totalPrice}
        </span>
      </div>

      {/* Second Row */}
      <div style={styles.uploadMinutesRow}>
        <span>
          {totalCredits} API Credits
        </span>
      </div>

      <div style={styles.approxMinutesRow}>{approxMinutes} Upload Minutes</div>

      <div id='get-minutes-slider'>
        <Slider
          value={creditsValue}
          onChange={setCreditsValue}
          min={1}
          max={8}
          step={1}
          tooltip={{ open: false }}
          trackStyle={styles.trackStyle}
        />
      </div>
      <StripeGetApiCreditsButton
        stripe_plan_id={'stripe_plan_id'}
        amount={totalPrice}
        quantity={1}
        credits={totalCredits}
        textColor='var(--text-on-color)'
        discount={0}
        label='Get API Credits'
      />
      <a className='card-documentation-button' href='https://docs.spikes.studio/' target='_blank' rel="noreferrer" >
        Documentation
      </a>
      <div style={styles.explanationsText}>
        <span>
          This is a one-time purchase and does not include a monthly/yearly subscription plan.
        </span>
      </div>
    </div>
  );
};

export default GetApiCreditsCard;
