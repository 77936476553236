import { Col, Modal, Row, Space, Image, Button, Typography, Tooltip } from 'antd';
import { useMemo } from 'react';
import { COLORS } from '../../themes/colors';
import Title from 'antd/lib/typography/Title';

import IconStar from '../../assets/icon-star.svg';
import IconStarFilled from '../../assets/icon-star-filled.svg';
import IconStarNoBlur from '../../assets/icon-star-no-blur.svg';
import { globalStyles } from '../../utils/globalStyles';

export interface ISpikeScoreProps {
  score: number;
  short?: boolean;
  isYoutubeClip?: boolean;
}

export const SpikeScore = ({ score, short = false, isYoutubeClip = false }: ISpikeScoreProps) => {
  const styles = useMemo(() => {
    return {

    } as const;
  }, []);

  if (short) {
    return (
      <Tooltip
        title={isYoutubeClip ? 'Virality Score' : 'Score based on quality, hype or performance.'}
        overlayInnerStyle={globalStyles().helpTooltip}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            style={{ marginRight: 8, fontSize: 20, fontWeight: 400 }}
          >
            {isYoutubeClip ? score.toFixed() : score.toFixed(1)}
          </Typography>
          <img src={IconStarNoBlur} />
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip title='Score based on quality, hype or performance.' overlayInnerStyle={globalStyles().helpTooltip}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography style={{ marginRight: 15, fontSize: 18, fontWeight: 600 }}>{score.toFixed(1)}</Typography>
        <img src={score >= 1 ? IconStarFilled : IconStar} style={{ marginLeft: score >= 1 ? -12 : 0 }} />
        <img src={score >= 2 ? IconStarFilled : IconStar} style={{ marginLeft: score >= 2 ? -12 : (score === 1 ? -6 : 0) }} />
        <img src={score >= 3 ? IconStarFilled : IconStar} style={{ marginLeft: score >= 3 ? -12 : (score === 2 ? -6 : 0) }} />
        <img src={score >= 4 ? IconStarFilled : IconStar} style={{ marginLeft: score >= 4 ? -12 : (score === 3 ? -6 : 0) }} />
        <img src={score >= 5 ? IconStarFilled : IconStar} style={{ marginLeft: score >= 5 ? -12 : (score === 4 ? -6 : 0) }} />
      </div>
    </Tooltip>
  );
};

export default SpikeScore;