import React, { useMemo } from 'react';
import { COLORS } from '../../../../themes/colors';
import { Image, Space, Typography } from 'antd';
import './SubtitlesSelectButton.less';
import {
  ISubtitleFilterItem,
  TextSettings,
} from '../subtitles-panel/SubtitlesPanel';

interface SubtitlesSelectButtonProps {
  title?: string;
  name: keyof TextSettings;
  item?: ISubtitleFilterItem;
  handleChange: (name: keyof TextSettings, value: string | number) => void;
  value: string;
}

const SubtitlesSelectButton: React.FC<SubtitlesSelectButtonProps> = ({
  title,
  name,
  item,
  handleChange,
  value,
}) => {
  const styles = useMemo(() => {
    return {
      buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px 24px',
        height: '100%',
      },
      title: {
        fontSize: '10px',
        lineHeight: '15px',
        fontWeight: 400,
        color: COLORS.LIGHT_GRAY_TEXT,
        marginBottom: '10px',
      },
      button: {
        height: '40px',
        width: '40px',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
      },
    } as const;
  }, []);

  if (!item) return null;

  const getClassnameForItem = (value: string) => {
    switch (value) {
      case 'Top':
        return 'subtitles-icon-top';
      case 'Middle':
        return 'subtitles-icon-middle';
      case 'Bottom':
        return 'subtitles-icon-bottom';
      default:
        return '';
    }
  };

  return (
    <div style={styles.buttonsContainer}>
      {title && <Typography style={styles.title}>{title}</Typography>}
      <Space size={8}>
        {item.options.map((option) => (
          <div
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            key={option.value}
            className={`subtitles-select-button ${getClassnameForItem(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              option.value,
            )} ${value === option.value && 'active'}`}
            style={styles.button}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onClick={() => handleChange(name, option.value)}
          >
            {option.icon && (
              <Image
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                src={option.icon}
                preview={false}
                className="cursor-pointer"
              />
            )}
          </div>
        ))}
      </Space>
    </div>
  );
};

export default SubtitlesSelectButton;
