import axios from 'axios';
import { StringIterator } from 'lodash';
import { UserContext } from '../data/userContext';
import { useContext } from 'react';

export const putUpdateProfile = async (params: any) => {
  await axios.put('/streamer/update-profile', {
    receive_post_stream_notifications: params.receive_post_stream_notifications,
    receive_marketing_emails : params.receive_marketing_emails,
    moderators_enabled : params.moderators_enabled
  }).catch((error) => {
    if (error.response) {
      console.log(error.response);
    }
  });
};


export const deleteUserAccount = async () => {
  await axios.delete('/streamer/delete-account').catch((error) => {
    if (error.response) {
      console.log(error.response);
    }
  });
};

// export const cancelSubscription = async (id : string) => {
//   const response = await axios.post('/streamer/cancel-subscription',{
//     id : id
//   }).catch((error) => {
//     if (error.response) {
//       console.log(error.response);
//       return null;
//     }
//   });

//   if (response?.status === 200) {
//     return response.data;
//   }
// };

export const makeStreamClips = async (id : string) => {
  const response = await axios.post('/streamer/create-stream-clips',{
    stream : id
  }).catch((error) => {
    if (error.response) {
      console.log(error.response);
      return null;
    }
  });

  if (response?.status === 200) {
    return response.data;
  }
};
