import React, { Component, ReactNode, ErrorInfo } from 'react';
// import { ReactComponent as ImageRobot } from './assets/error-robot.svg';
import { ReactComponent as ImageRobot } from './assets/robot-black.svg';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Error Boundary caught an error", error, errorInfo);
  }

  render() {
    const isHomePage = window.location.pathname === '/';

    if (this.state.hasError && !isHomePage) {
      return (
        <div className='error-boundary'>
          <div className='error-boundary__content'>
            <div className='error-boundary__text'>
              <h1>Oops! Something went wrong.</h1>
              <p>We&apos;re sorry for the inconvenience. Please try again later.</p>
              <div className='error-boundary__reload-button' onClick={() => window.location.reload()}>Reload</div>
            </div>
            <div className='error-boundary__image'>
              <ImageRobot />
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
