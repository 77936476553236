import { Col, Modal, Row, Space, Checkbox, Button, Typography, Image, Divider } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { COLORS } from '../../themes/colors';
import Title from 'antd/lib/typography/Title';
import { UserContext } from '../../data/userContext';
import { useNavigate } from 'react-router';
import { putUpdateProfile } from '../../services/streamer.service';
import SwitchButton from '../buttons/switch/SwitchButton';
import { ReactComponent as IconTwitch } from '../../assets/icon-twitch-black.svg';
import IconClose from '../../assets/modal-close.svg';
import SettingIcon from '../../assets/settin-modal-icon.svg';
import ModeratorIcon from '../../assets/moderator-icon.svg';
import MarketingEmailIcon from '../../assets/marketing-email-icon.svg';
import PostStreamIcon from '../../assets/post-stream-icon.svg';

export interface ISettingsModalProps {
  onClose: () => void,
}

export const SettingsModal = ({ onClose }: ISettingsModalProps) => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const isGoogleUser = !userContext?.user?.twitch_id;

  const [post_stream_notifications_checked, set_post_stream_notifications] = useState(userContext?.user?.receive_post_stream_notifications);
  const [marketing_emails, set_marketing_emails] = useState(userContext?.user?.receive_marketing_emails);
  const [favorite_view_use_twitch_clips, set_favorite_view_use_twitch_clips] = useState(userContext?.user?.favorite_view_use_twitch_clips);
  const [favorite_view_include_4_star_ranking_spikes, set_favorite_view_include_4_star_ranking_spikes] = useState(userContext?.user?.favorite_view_include_4_star_ranking_spikes);
  const [moderators_enabled, set_moderators_enabled] = useState(userContext?.user?.moderators_enabled);

  const subscriptions = userContext?.user?.subscriptions;// && userContext?.user?.subscriptions.length > 0 ? userContext?.user?.subscriptions?.length;
  const existingSubscription = subscriptions && subscriptions?.length > 0 ? subscriptions[0] : null;

  const [open, setOpen] = useState<boolean>(true);

  const styles = useMemo(() => {
    return {
      checkboxStyle: {
        fontSize: 18,
        marginTop: 10,
        marginLeft: 0,
      },
      modalStyle: {
        borderRadius: 10,
        overflow: 'hidden',
      },
      modalTitle: {
        fontSize: '26px',
        fontWeight: 600,
        marginBottom: 8,
        textAlign: 'left',
        color: 'var(--text-base)'
      },
      modalBody: {
        background: 'var(--base)',
        padding: '20px',
      },
      description: {
        fontSize: 20,
        fontFamily: 'Poppins',
        fontWeight: 300,
        textAlign: 'center',
        marginTop: 20,
      },
      item: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      itemText: {
        fontSize: 20,
        fontWeight: 400,
        color: 'var(--text-base)',
      },
      itemTextSecondary: {
        fontSize: 14,
        color: 'var(--text-subdued)',
        paddingRight: '10px',
      },
      recommendedText: {
        fontSize: 16,
        color: COLORS.PRIMARY
      },
      settingIconWrapper: {
        background: 'var(--bg-brand-secondary)',
        padding: '4px 12px',
        borderRadius: '8px'
      },
      IconWrapper: {
        background: 'var(--bg-brand-secondary)',
        padding: '16px 16px',
        borderRadius: '8px',
        textAlign: 'center',
        marginRight: '15px'
      },
      SwitchStyle: {
        height: '32px',
        width: '64px'
      },

    } as const;
  }, []);

  const onDeleteClick = () => {
    onClose();
    navigate('/spikes/delete-account');
  };

  const onCancelSubscriptionClick = () => {
    onClose();
    navigate('/spikes/cancel-subscription');
  };

  const onSave = async () => {
    // save data and update user context
    const user = userContext!.user;
    user!.receive_post_stream_notifications = post_stream_notifications_checked || false;
    user!.receive_marketing_emails = marketing_emails || false;
    user!.moderators_enabled = moderators_enabled || false;
    userContext?.setUser(user!);

    putUpdateProfile({
      receive_post_stream_notifications: post_stream_notifications_checked,
      receive_marketing_emails: marketing_emails,
      moderators_enabled: moderators_enabled
    });

    onClose();
  };

  const check_post_stream_notifications = () => {
    set_post_stream_notifications(!post_stream_notifications_checked);
  };

  const check_marketing_emails = () => {
    set_marketing_emails(!marketing_emails);
  };

  const check_moderators_enabled = () => {
    set_moderators_enabled(!moderators_enabled);
  };

  const onConnectWithTwitch = () => {
    return;
  };

  return (
    <Modal
      open={open}
      footer={null}
      bodyStyle={styles.modalBody}
      style={styles.modalStyle}
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      onCancel={onClose}
      closable={false}
      maskClosable={false}
      width={720}
      centered
    >
      <div style={{ position: 'absolute', top: 20, right: 20 }} className='cursor-pointer' onClick={onClose}>
        <Image src={IconClose} preview={false} width={24} />
      </div>
      <div className="settings-section" style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
        <Typography style={styles.modalTitle}> <span style={styles.settingIconWrapper}> <Image src={SettingIcon} preview={false} width={20} /></span> Settings</Typography>
        {!isGoogleUser &&
          <div style={styles.item}>
            <span style={styles.IconWrapper}> <Image src={PostStreamIcon} preview={false} width={40} /> </span>
            <div>
              <Typography style={styles.itemText}>Receive Post stream notifications</Typography>
              <Typography style={styles.itemTextSecondary}>Enable this option to receive notifications whenever new content is streamed on your feed. </Typography>
            </div>
            <SwitchButton value={post_stream_notifications_checked} onChange={check_post_stream_notifications} />
          </div>
        }
        <Divider style={{ background: 'var(--border-neutral-default)', margin: 0 }} />
        <div style={styles.item}>
          <span style={styles.IconWrapper}> <Image src={MarketingEmailIcon} preview={false} width={40} /> </span>
          <div>
            <Typography style={styles.itemText}>Receive Marketing Emails</Typography>
            <Typography style={styles.itemTextSecondary}>Turn this on to get the latest updates, offers, and promotions directly in your email inbox.</Typography>
          </div>
          <SwitchButton value={marketing_emails} onChange={check_marketing_emails} />
        </div>
        <Divider style={{ background: 'var(--border-neutral-default)', margin: 0 }} />
        {!isGoogleUser &&
          <>
            <div style={styles.item}>
              <span style={styles.IconWrapper}> <Image src={ModeratorIcon} preview={false} width={40} /> </span>
              <div>
                <Typography style={styles.itemText}>Allow moderators to capture clips</Typography>
                <Typography style={styles.itemTextSecondary}>Activating this allows moderators to create and share short clips from your streams or content</Typography>
              </div>
              <SwitchButton value={moderators_enabled} onChange={check_moderators_enabled} />
            </div>
            <Divider style={{ background: 'var(--border-neutral-default)', margin: 0 }} />
          </>
        }


        {/* {isGoogleUser && <Button style={{ background: '#8D41FE', color: '#000' }} onClick={onConnectWithTwitch} type="primary" className="button button--dark"><IconTwitch width={24} height={24} style={{ fill: '#000', marginRight: 15, marginLeft: -3 }} />Connect with Twitch</Button>} */}

        <div className='settings__bottom-buttons'>
          <Button onClick={onDeleteClick} type="primary" className="button button--dark delete-account-button" style={{ flexBasis: '10%' }}>Delete Account</Button>
          {existingSubscription && existingSubscription !== '' &&
            <Button type="primary" className="button button--dark cancel-subscribtion-button" onClick={onCancelSubscriptionClick} style={{ flexBasis: '10%', }}>Cancel Subscription</Button>
          }
          <div className='divider-save' style={{ flexBasis: '40%' }}></div>
          <Button onClick={onSave} type="primary" className="button save-settings-button" style={{ flexBasis: '50%' }}>Save</Button>
        </div>


      </div>
    </Modal>
  );
};

export default SettingsModal;
