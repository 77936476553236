import { Col, Modal, Row, Space, Checkbox, Button, Typography, Image, Divider } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { COLORS } from '../../themes/colors';
import Title from 'antd/lib/typography/Title';
import { UserContext } from '../../data/userContext';
import { useNavigate } from 'react-router';
import { deleteUserAccount } from '../../services/streamer.service';
import IconClose from '../../assets/modal-close.svg';
import DeleteIcon from '../../assets/icon-delete.svg';
import { ReactComponent as GetHelpIcon } from '../../assets/get-help-icon.svg';



import './DeleteAccountModal.less'
import { DISCORD_LINK } from '../../constants/content-constants';

export const DeleteAccountModal = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const [open, setOpen] = useState<boolean>(true);

  const styles = useMemo(() => {
    return {

      modalStyle: {
        borderRadius: 10,
        overflow: 'hidden',
      },
      modalTitle: {
        fontSize: '26px',
        fontWeight: 600,
        marginBottom: 8,
        textAlign: 'left',
        color: 'var(--text-base)'
      },

      modalBody: {
        background: 'var(--base)',
        padding: '20px',
      },
      container: {
        border: '1px solid var(--border-neutral-default)',
        borderRadius: '12px',
        padding: '16px'
      },
      description: {
        fontSize: 20,
        fontFamily: 'Poppins',
        fontWeight: 300,
        textAlign: 'center',
        marginTop: 20,
      },
      item: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      itemText: {
        fontSize: 20,
        fontWeight: 400,
        color: 'var(--bg-status-error-light)',
      },
      itemTextSecondary: {
        fontSize: 14,
        color: 'var(--text-subdued)',
        paddingRight: '10px',
        marginBottom: 20
      },
      helpText: {
        color: 'var(--text-base)',
        fontWeight: 800,
        marginBottom: 5
      },
      getHelp: {
        fontSize: 16,
        color: 'var(--text-base)',
        marginBottom: 15,
        display: 'flex',
        alignItems: 'center',
        gap: '10px'
      },
      getHelpLink: {
        color: 'var(--text-brand-primary',
        fontWeight: 400
      },
      deleteIconWrapper: {
        background: 'var(--bg-brand-secondary)',
        padding: '4px 12px',
        borderRadius: '8px',
        marginRight: 5
      },


    } as const;
  }, []);
  const onLeave = async () => {
    await deleteUserAccount();
    localStorage.removeItem('user');
    // window.location.href = '/login/account-deleted';
    window.location.href = '/account-deleted';
  };


  const onClose = () => {
    setOpen(false);
    setTimeout(() => {
      navigate('/');
    }, 300);
  };

  return (
    <Modal
      open={open}
      footer={null}
      bodyStyle={styles.modalBody}
      style={styles.modalStyle}
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      onCancel={onClose}
      closable={false}
      maskClosable={false}
      width={720}
      centered
    >
      <div style={{ position: 'absolute', top: 20, right: 20 }} className='cursor-pointer' onClick={onClose}>
        <Image src={IconClose} preview={false} width={24} />
      </div>
      <div className="settings-section" style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
        <Typography style={styles.modalTitle}> <span style={styles.deleteIconWrapper}> <Image src={DeleteIcon} preview={false} width={20} /></span> Delete Account</Typography>
        <Divider style={{ background: 'var(--border-neutral-default)', margin: 0 }} />
        <div style={styles.item}>

          <div style={styles.container}>
            <Typography style={styles.itemText}>Deleting your account is permanent</Typography>
            <Typography style={styles.itemTextSecondary}>Before you go, we want to ensure you&apos;ve considered all your options. It means losing all your data and access to our services. </Typography>
            <Typography style={styles.itemTextSecondary}>If you&apos;re facing any issues or there&apos;s something we can improve, we&apos;re here to help. </Typography>
            <Typography style={styles.itemTextSecondary}>Would you like to delete your account or explore other solutions with us first? </Typography>

          </div>

        </div>
        <div >
          <div style={styles.getHelp}>
            <span>Get Help</span>
            <GetHelpIcon style={{ fill: 'var(--text-base)' }} />
          </div>
          <div style={styles.container}>
            <Typography style={styles.helpText}>Help Center: <a style={styles.getHelpLink} href="https://intercom.help/spikes-studio/en/" > https://intercom.help/spikes-studio/en/</a></Typography>
            {/* <Typography style={styles.helpText}>24/7 Customer Support: <a style={styles.getHelpLink} href={DISCORD_LINK} > {DISCORD_LINK}</a></Typography> */}
          </div>
        </div>



        <div style={{ display: 'flex', justifyContent: 'start' }}>
          <div style={{ flexBasis: '55%', }}>
            <Typography style={{ color: 'var(--text-base)', fontSize: 20 }}>Delete Account</Typography>
            <Typography style={{ color: 'var(--text-subdued)', paddingRight: 5 }}>Deleting your account will permanently erase all your data, including your profile, settings, and content, and cannot be undone.</Typography>
          </div>
          <div style={{ flexBasis: '45%', display: 'flex', marginTop: 10, justifyContent: 'space-between', gap: 10 }}>
            <div onClick={onLeave} className="settings-button delete">Confirm</div>
            <div onClick={onClose} className="settings-button stay">I&apos;ll Stay :)</div>
          </div>

        </div>


      </div>
    </Modal>
  );
};

export default DeleteAccountModal;












