import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { PayPalButton } from 'react-paypal-button-v2';
import { message } from 'antd';
import { UserContext } from '../../data/userContext';
import { useNavigate } from 'react-router';
import { postAudit } from '../../services/audit.service';
const paypal_client_id = process.env.REACT_APP_PAYPAL_CLIENT_ID;

type Transaction = {
  ppOrderId: string;
  ppAuthId: string;
};


export interface IPaypalSubscriptionButton {
  onTransactionCompleted: any;
  paypal_plan_id: string;
  amount: number;
  quantity: number;
  minutes: number;
}


export const PaypalSubscriptionButton = ({ onTransactionCompleted, paypal_plan_id, amount, quantity, minutes }: IPaypalSubscriptionButton) => {
  const [isLoading, setIsLoading] = useState(true);
  const [forceRender, setForceRender] = useState(false);
  const userContext = useContext(UserContext);

  const handleButtonClick = () => {
    // Update the state to trigger a rerender
    setForceRender(prevState => !prevState);
  };

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        handleButtonClick();
      }, 600);
    }
  }, [isLoading]);

  const onApproveHandler = async (data: any, actions: any) => {
    const orderId = data.orderID;
    const authId = data.subscriptionID;

    const params = {
      planId: paypal_plan_id,
      orderId: orderId,
      authId: authId,
      amount: amount,
      minutes,
      platform: 'paypal'
    };

    try {
      let rc = true;
      await axios
        .post('/streamer/subscription', params)
        .then(async (response) => {
          if (response.status === 200) {
            rc = true;
            const itemName = response.data.subscription.subscription_plan === '9948eedd-358c-4356-84bb-d1df7f15a389' ? 'Pro' : 'Enterprise';
            const billing_cycle = response.data.subscription.subscription_plan === '9948eedd-358c-4356-84bb-d1df7f15a389' ? 'Monthly' : 'Yearly';
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event_name: 'purchase',
              currency: response.data.subscription.details.currency_code,
              value: response.data.subscription.details.amount,
              items:
                [{
                  item_name: itemName,
                  item_id: response.data.subscription.subscription_plan,
                  minutes_count: response.data.subscription.upload_minutes,
                  price: response.data.subscription.details.amount
                }],
              billing_cycle: billing_cycle,
              transaction_id: response.data.subscription.details.id,
              method: 'Paypal',
              event: 'fireEvent',
            });
          } else {
            rc = false;
          }
        })
        .catch(async (error) => {
          postAudit({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            user_action: `Paypal subscription failed - ${error}`,
            user_id: userContext?.user?.id
          });
          rc = false;
          if (error.response) {
            console.log('error');
          }
        });

      onTransactionCompleted(rc);
    } catch (err) {
      postAudit({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        user_action: `subscription failed - paypal - ${err}`,
        user_id: userContext?.user?.id
      });
      onTransactionCompleted(false);
    }
  };

  return (
    <>
      <PayPalButton
        shippingPreference="NO_SHIPPING"
        onError={async (err: any) => {
          postAudit({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            user_action: `subscription failed - paypal - ${err}`,
            user_id: userContext?.user?.id
          });
          await onTransactionCompleted(false);
        }}
        createSubscription={(data: any, actions: any) => {
          return actions.subscription.create({ plan_id: paypal_plan_id, quantity: quantity });
        }}
        onApprove={onApproveHandler}
        onButtonReady={() => {
          setIsLoading(false);
        }}
        options={{
          clientId: paypal_client_id,
          currency: 'USD',
          intent: 'subscription',
          disableFunding: 'card',
          vault: true,
        }}
      />
      {!!isLoading && <div>Loading...</div>}
    </>
  );
};

export default PaypalSubscriptionButton;