import React, { useRef, useEffect, useState } from 'react';

interface SoundDataPoint {
  time_seconds: number;
  decibel: number;
}

interface GradientSoundWaveProps {
  data: SoundDataPoint[];
  height?: number; // Optional: allows customization of SVG height
  colorStart?: string; // Optional: gradient start color
  colorEnd?: string; // Optional: gradient end color
}

const GradientSoundWave: React.FC<GradientSoundWaveProps> = ({
  data,
  height = 40,
  colorStart = '#4A90E2',
  colorEnd = '#50E3C2',
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({
    width: 0,
    height,
  });

  // Update dimensions based on container size
  useEffect(() => {
    const handleResize = () => {
      if (svgRef.current && svgRef.current.parentElement) {
        const { clientWidth } = svgRef.current.parentElement;
        setDimensions({ width: clientWidth, height });
      }
    };

    handleResize(); // Initial size
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [height]);

  // Find min and max decibel values for scaling
  const minDecibel: number = Math.min(...data.map((point) => point.decibel));
  const maxDecibel: number = Math.max(...data.map((point) => point.decibel));

  // Function to map decibel to Y position
  const mapDecibelToY = (decibel: number, height: number): number => {
    if (minDecibel === maxDecibel) {
      return height / 2;
    }
    return ((decibel - minDecibel) / (maxDecibel - minDecibel)) * height;
  };

  // Generate SVG path data
  const generatePath = (): string => {
    const { width, height } = dimensions;
    if (width === 0) return '';

    const step: number = width / data.length;
    let path = '';

    data.forEach((point, index) => {
      const x: number = index * step;
      const y: number = mapDecibelToY(point.decibel, height);
      path += `${index === 0 ? 'M' : 'L'} ${x} ${y} `;
    });

    path += `L ${dimensions.width} ${dimensions.height} L 0 ${dimensions.height} Z`;

    return path;
  };

  const pathData: string = generatePath();

  return (
    <svg
      ref={svgRef}
      width="100%"
      height={dimensions.height}
      viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
      preserveAspectRatio="none"
      aria-label="Gradient Sound Wave"
      role="img"
    >
      <path
        d={pathData}
        fill={`url(#gradientWave_${height})`}
        stroke="none"
        opacity={0.9}
      />
      <defs>
        <linearGradient id={`gradientWave_${height}`} x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor={colorStart} />
          <stop offset="100%" stopColor={colorEnd} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GradientSoundWave;
