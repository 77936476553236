import { Image, Typography, message } from 'antd';
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { COLORS } from '../../themes/colors';
import { debounce } from 'lodash';

import axios from 'axios';
import { ITwitchVideo, IYoutubeVideo, IVimeoVideo, IGoogleDriveVideo } from '../../data/intefaces/video.interface';
import { UserContext } from '../../data/userContext';
import { isTwitchUrl, isYoutubeUrl, isVimeoUrl, isGoogleDriveUrl, roundWithTwoDecimals } from '../../utils';
import { ReactComponent as IconCheck } from '../../assets/green-check.svg';
import IconClose from '../../assets/modal-close.svg';

export const SpikesGuide = memo(({ handleClose }: { handleClose: () => void }) => {

  const styles = useMemo(() => {
    return {
      container: {
        width: '100%',
        borderRadius: 8,
        backgroundColor: 'var(--base)',
        padding: '30px 35px 17px 35px',
        position: 'relative',
        border: '1px solid var(--border-neutral-default)',
      },
      title: {
        marginBottom: 12,
        fontSize: 20,
        fontWeight: 500,
        color: 'var(--text-base)'
      },
      description: {
        marginBottom: 24,
        fontWeight: 400,
        color: 'var(--text-base)'
      },
      item: {
        display: 'flex',
        gap: 15,
        alignItems: 'center'
      },
      itemText: {
        fontSize: 14,
        color: 'var(--text-base)',
        lineHeight: '24px',
        fontWeight: 400
      },
      itemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12
      },
      closeIcon: {
        position: 'absolute',
        top: 20,
        right: 20,
        cursor: 'pointer'
      }
    } as const;
  }, []);

  return (
    <div style={styles.container}>
      <div onClick={handleClose} style={styles.closeIcon}><Image src={IconClose} preview={false} width={18} /></div>
      <div style={styles.title}>Start here</div>
      <div style={styles.description}>Insert above a supported link or upload a video from your computer. Our cutting-edge technology will transform your video into short clips, ready to upload to social media.</div>
      <div style={styles.itemsContainer}>
        <div style={styles.item}>
          <IconCheck style={{ stroke: 'var(--bg-brand-primary)' }} />
          <div style={styles.itemText}>YouTube Video Links</div>
        </div>
        {/* <div style={styles.item}>
          <IconCheck />
          <div style={styles.itemText}>Vimeo Video Links</div>
        </div> */}
        <div style={styles.item}>
          <IconCheck style={{ stroke: 'var(--bg-brand-primary)' }} />
          <div style={styles.itemText}>Twitch Video Links</div>
        </div>
        <div style={styles.item}>
          <IconCheck style={{ stroke: 'var(--bg-brand-primary)' }} />
          <div style={styles.itemText}>Google Drive Video links</div>
        </div>
      </div>
    </div>
  );
});

SpikesGuide.displayName = 'SpikesGuide';


export default SpikesGuide;