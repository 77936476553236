import React, { useState, useContext, useEffect } from 'react';
import {
  Layout,
  Menu,
  Drawer,
  Button,
  Image,
  Space,
  Avatar,
  Typography,
  Tooltip,
} from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { COLORS } from '../../../themes/colors';
import { UserContext } from '../../../data/userContext';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/spikes-logo.svg';
import { useNavigate } from 'react-router-dom';
import IconLogout from '../../../assets/icon-menu-logout.svg';
import IconTimer from '../../../assets/timer.svg';
import IconSettings from '../../../assets/icon-menu-settings.svg';
import RightArrowSmall from '../../../assets/right-arrow-small.svg';
import RightArrow from '../../../assets/right-arrow.svg';
import { ReactComponent as IconHamburger } from '../../../assets/hamburger.svg';
import { ReactComponent as IconClose } from '../../../assets/close-circle.svg';
import { SettingsModal } from '../../settings-modal';
import { LoginModal } from '../../login-modal';
import {
  UsergroupAddOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { ReactComponent as IconClock } from '../../../assets/clock.svg';

import './HeaderResponsive.less';
import HeaderMenu from './HeaderMenu/HeaderMenu';
import { GOOGLE_USER } from '../../../constants/user-constants';
import ProducthuntButton from '../../../components/product-hunt-button/ProducthuntButton';
import { isMobile } from 'react-device-detect';
import Banner from '../../banner';
import { HappyHourModal } from '../../happy-hour-modal';
import { getTimeRangeBetweenCurrentAndGivenDate, roundWithTwoDecimals, secondsToMinutesAndSeconds, showGoProButton } from '../../../utils';
import { Sidebar } from '../../sidebar';
import { DISCORD_LINK } from '../../../constants/content-constants';

const { Header } = Layout;

const HeaderResponsive = () => {
  const [visible, setVisible] = useState(false);
  const userContext = useContext(UserContext);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [showDiscountBanner, setShowDiscountBanner] = useState<boolean>(false);
  const [isOpenHappyHour, setIsOpenHappyHour] = useState<boolean>(false);
  const [hideHappyHour, setHideHappyHour] = useState<boolean>(false);
  const subscriptions = userContext?.user?.subscriptions;
  const usedStreams = userContext?.user?.used_streams;
  const usedMinutes = userContext?.user?.used_upload_minutes;
  const totalStreams = userContext?.user?.total_streams;
  const totalMinutes = userContext?.user?.total_upload_minutes;
  const hasSubscription = userContext?.user?.subscriptions && userContext?.user?.subscriptions[0];
  const isGoogleUser = !userContext?.user?.twitch_id;
  const isSpikesOrEditURL =
    location.pathname.includes('/spikes') ||
    location.pathname.includes('/edit');

  const visits = parseInt(localStorage.getItem('visits') || '0');
  const happyHourWasShown = localStorage.getItem('happyHourWasShown');

  const showHappyHour = false; //visits === 3 && !userContext?.user?.subscriptions[0];

  useEffect(() => {
    const showBanner = () => {
      const discountBannerSeen = localStorage.getItem('discountBannerSeen');
      if (!hasSubscription && !discountBannerSeen && typeof usedMinutes === 'number' && typeof usedStreams === 'number') {
        if (calculatePercentage(usedMinutes, totalMinutes) <= 34) {
          return true;
        } else if (calculatePercentage(usedStreams, totalStreams) <= 34) {
          return true;
        }
      }
      return false;
    };

    setShowDiscountBanner(showBanner());
  }, [hasSubscription, usedStreams, usedMinutes, totalStreams, totalMinutes]);

  useEffect(() => {
    if (showHappyHour && !happyHourWasShown) {
      setIsOpenHappyHour(true);
      localStorage.setItem('happyHourWasShown', JSON.stringify(Date.now()));
    }
  }, []);

  const happyHourTime = happyHourWasShown ? getTimeRangeBetweenCurrentAndGivenDate(JSON.parse(happyHourWasShown)) : null;
  const happyHourTimeIsStillAvailable = happyHourTime ? (3600 - happyHourTime) > 0 : true;

  const logout = () => {
    localStorage.removeItem('user');
    window.location.href = '/';
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const toggleDrawer = () => {
    setVisible(prev => !prev);
  };

  const onClose = () => {
    setVisible(false);
  };

  const navigate = useNavigate();

  const getLogoHeight = () => {
    if (isMobile && location.pathname === '/') return 23;
    return isMobile ? 24 : 48;
  };

  const getLogoWidth = () => {
    if (isMobile && location.pathname === '/') return 127;
    return isMobile ? 120 : 200;
  };

  const calculatePercentage = (used?: number, total?: number) => {
    if (typeof used === 'number' && typeof total === 'number') {
      return ((total - used) * 100) / total;
    } else {
      return 0;
    };
  };

  const handleCloseBanner = () => {
    localStorage.setItem('discountBannerSeen', 'true');
    setShowDiscountBanner(false);
  };

  const styles = {
    header: {
      position: 'fixed',
      top: showDiscountBanner ? '36px' : '0px',
      left: '0px',
      right: '0px',
      width: '100%',
      backgroundColor: '#0A0A0A87',
      backdropFilter: 'blur(24px)',
      textAlign: 'center',
      zIndex: '999',
      paddingLeft: isMobile ? 15 : 50,
      paddingRight: isMobile ? 15 : 50,
      height: isMobile ? 'unset' : 106,

      paddingTop: isMobile ? 10 : 20,
      paddingBottom: isMobile ? 10 : 20
    },
    headerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 1700,
      margin: '0 auto',
      alignItems: 'center',
      lineHeight: 'initial'
    },
    logo: {
      width: getLogoWidth(),
      height: getLogoHeight(),
      background: `url(${Logo}) no-repeat center center`,
      backgroundSize: 'contain',
      margin: isMobile ? 0 : '10px 14px 10px 0',
      float: 'left',
    },
    desktopMenu: {
      width: '100%',
      justifyContent: 'flex-end',
      background: 'transparent',
    },
    drawerMenu: {
      background: 'transparent',
      border: 'none',
      flexDirection: 'column',
    },
    startNow: {
      minHeight: '35px',
    },

    container: {
      paddingTop: 20,
      width: '100%',
    },
    hello: {
      fontFamily: 'Poppins',
      fontSize: 36,
      marginBottom: 0,
    },
    loggedInTitle: {
      fontWeight: 200,
    },
    login: {
      fontFamily: 'Poppins',
      fontSize: 28,
      marginBottom: 0,
      fontWeight: 400,
      marginTop: 30,
    },
    avaterContainer: {
      textAlign: 'right',
      position: 'relative',
      display: 'flex'
    } as React.CSSProperties,
    avatar: {
      backgroundColor: '#fff',
      verticalAlign: 'middle',
      borderRadius: 5,
      fontFamily: 'Arial',
      fontSize: 16,
      border: '1px solid black',
      textTransform: 'uppercase' as const, // Set the correct textTransform value here
      color: '#000',
      fontWeight: 'bold',
    },
    menuOverlayInnerStyle: {
      background: 'rgba(35, 38, 43, 0.8)',
      border: 0,
      borderRadius: 22,
      padding: '20px 25px',
      backdropFilter: 'blur(4px)',
      width: 250,
      marginTop: 10,
    },
    menuItemText: {
      color: 'rgba(244, 244, 244, 0.8)',
      fontSize: 18,
      fontWeight: 500,
      marginTop: 3,
    },
    dropdownLink: {
      display: 'flex',
      alignItems: 'center',
      color: '#FFF',
      fontSize: 16,
      fontWeight: 500,
      marginRight: 5,
    },
    dropdownMenu: {
      background: '#121212', // Green theme
      border: 0,
      padding: '10px 0',
    },
    dropdownMenuItem: {
      color: '#FFF',
      fontSize: 16,
      fontWeight: 500,
      padding: '8px 24px',
      lineHeight: '1.5',
    },
    upgradeContainer: {
      borderRadius: showHappyHour && happyHourTimeIsStillAvailable ? '4px 4px 0 0' : 25,
      padding: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      maxHeight: 46,
      minWidth: 152,
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      background: '#FAF969'
    },
    getMinutesContainer: {
      borderRadius: 25,
      padding: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      maxHeight: 46,
      minWidth: 152,
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    upgradeContainerMenu: {
      borderRadius: showHappyHour && happyHourTimeIsStillAvailable ? '4px 4px 0 0' : 8,
      padding: 12,
      display: 'flex',
      alignItems: 'center',
      maxHeight: 46,
      minWidth: 152,
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      background: '#FAF969',
      justifyContent: 'space-between',
    },
    goPro: {
      fontSize: 20,
      color: '#000',

    },
    getMinutes: {
      fontSize: 20,
      color: '#000',

    },
    joinFree: {
      height: 55,
      width: 160,
      fontSize: 24,
      fontWeight: 600,
      margin: '0 auto'
    },
    startNowButton: {
      cursor: 'pointer',
      color: '#e4e4e4'
    },
    pricingButton: {
      cursor: 'pointer',
      color: '#fff'
    },
    timerContainer: {
      background: COLORS.SYSTEM_RED,
      borderRadius: '0 0 4px 4px',
      padding: '3px 12px',
      fontSize: 12,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      userSelect: 'none',
      cursor: 'pointer'
    },
    spikesPro: {
      color: '#FFFE58',
      fontFamily: 'Poppins',
      fontSize: 20,
      fontWeight: 500,
      width: '50px'



    }
  };

  const desktopDropdownMenu = (
    <Menu className="dropdownMenu" style={styles.dropdownMenu}>
      <Menu.Item key="6" style={styles.dropdownMenuItem}>
        <Link
          to="/marketing-experts
"
          style={styles.dropdownLink}
        >
          Marketing Experts
        </Link>
      </Menu.Item>
      <Menu.Item key="7" style={styles.dropdownMenuItem}>
        <Link to="/live-streamers" style={styles.dropdownLink}>
          Live Streamers
        </Link>
      </Menu.Item>
      <Menu.Item key="8" style={styles.dropdownMenuItem}>
        <Link to="/game-developers" style={styles.dropdownLink}>
          Game Developers
        </Link>
      </Menu.Item>
    </Menu>
  );
  const content = (
    <>
      <Space
        className="uppercase cursor-pointer display-flex"
        size="middle"
        onClick={() => navigate('/affiliate-program')}
      >
        <UsergroupAddOutlined style={styles.menuItemText} />
        <Typography style={styles.menuItemText}>Invite Friends</Typography>
      </Space>
      <Space
        className="uppercase cursor-pointer display-flex"
        size="middle"
        onClick={() => setShowSettingsModal(true)}
      >
        <Image src={IconSettings} preview={false} width={20} />
        <Typography style={styles.menuItemText}>Settings</Typography>
      </Space>
      <Space className="uppercase cursor-pointer display-flex" size="middle">
        <QuestionCircleOutlined style={styles.menuItemText} />
        <a
          href="https://intercom.help/spikes-studio/en/"
          target="_blank"
          rel="noreferrer"
          style={styles.menuItemText}
        >
          FAQ
        </a>
      </Space>
      <Space
        className="uppercase cursor-pointer display-flex"
        size="middle"
        onClick={logout}
      >
        <Image src={IconLogout} preview={false} width={20} />
        <Typography style={styles.menuItemText}>Logout</Typography>
      </Space>
    </>
  );

  const handleShowSettings = () => {
    onClose();
    setShowSettingsModal(true);
  };

  const handleNavigateToUploads = () => {
    onClose();
    navigate('/spikes/uploads');
  };

  const handleNavigateToStreams = () => {
    onClose();
    navigate('/spikes/streams');
  };

  const handleNavigateToVideoAI = () => {
    onClose();
    navigate('/spikes/videos');
  };
  const handleNavigateToTranscribe = () => {
    onClose();
    navigate('/spikes/transcribe');
  };
  const handleNavigateLoayaltyProgram = () => {
    onClose();
    navigate('loayalty-program');
  };

  const handleTutorialRun = () => {
    onClose();
    localStorage.removeItem('uploadsTabTutorialWatched');
    localStorage.removeItem('editorTutorialWatched');
    localStorage.removeItem('makeClipsTutorialWatched');
    navigate('/spikes/uploads', { state: { triggerTutorial: true } });
  };

  const openHappyHourModal = () => {
    setIsOpenHappyHour(true);
  };

  const isNumber = (value: any) => {
    if (typeof value === 'number') return true;
    else return false;
  };

  const CreditsTimer = ({ isStreams = false }: { isStreams?: boolean }) => {
    const streamsLeft = isNumber(totalStreams) && isNumber(usedStreams) && roundWithTwoDecimals(totalStreams! - usedStreams!);
    const minutesLeft = isNumber(totalMinutes) && isNumber(usedMinutes) && roundWithTwoDecimals(totalMinutes! - usedMinutes!);

    const tooltipText = isStreams ? `You have ${streamsLeft} streams available` : `You have ${minutesLeft} minutes available`;

    if (!userContext?.user) return <></>;
    return (
      <Tooltip placement='bottom' title={tooltipText}>
        <div className="credits-timer">
          <IconClock />
          <span>{isStreams ? `${totalStreams === 60 ? 'Unlimited' : streamsLeft} streams` : `${minutesLeft}m`}</span>
        </div>
      </Tooltip>
    );
  };

  return (
    <div>
      {showDiscountBanner && <Banner handleClose={handleCloseBanner} />}
      <Header
        className="main-header"
        style={styles.header as React.CSSProperties}
      >
        <div className="wrapper" style={styles.headerWrapper}>
          <div className="main-header__logo-wrapper">
            <Link to="/" style={styles.logo as React.CSSProperties}></Link>
            {subscriptions?.length != 0 && subscriptions != null ? <span style={styles.spikesPro}>PRO</span> : <></>}
          </div>
          <Menu
            theme="light"
            mode="horizontal"
            className="desktop-menu"
            style={styles.desktopMenu as React.CSSProperties}
          >
            {/* <Menu.Item key="2">
              <a
                href="https://intercom.help/spikes-studio/en/"
                target="_blank"
                rel="noreferrer"
              >
                FAQ
              </a>
            </Menu.Item> */}

            {location.pathname === '/' &&
              <Menu.Item key="1">
                <div
                  onClick={() => navigate('/about')}
                >
                  About
                </div>
              </Menu.Item>
            }
            {/* <Menu.Item key="3">
              <a
                href={DISCORD_LINK}
                target="_blank"
                rel="noreferrer"
              >
                Discord
              </a>
            </Menu.Item> */}
            <div style={{ display: 'flex', alignItems: 'center', gap: 20, marginRight: 20 }} key="1">
              {!isGoogleUser && <CreditsTimer isStreams />}
              <CreditsTimer />
            </div>


            {showGoProButton(userContext?.user) ?
              <div>
                <div id='go-pro-button' style={styles.upgradeContainer} className="upgradeContainer cursor-pointer primary-gradient-button" onClick={() => navigate('/subscriptions')}>
                  <Typography style={styles.goPro}>Go Pro</Typography>
                  <Image src={RightArrowSmall} preview={false} />
                </div>

                {happyHourTimeIsStillAvailable && showHappyHour && !hideHappyHour &&
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore 
                  <div id='50-off-button' onClick={openHappyHourModal} style={styles.timerContainer}>
                    <HappyHourTimer setHideHappyHour={setHideHappyHour} withDiscountText />
                  </div>
                }
                {/* Get Minutes When user is pro button */}
              </div> :
              null
              // (userContext?.user) &&
              // <div id='get-minutes-button' style={styles.getMinutesContainer} className="cursor-pointer" onClick={() => navigate('/dashboard')}>
              //   <Typography style={styles.getMinutes}>Get Minutes</Typography>
              // </div>
            }

            {!userContext?.user &&
              <Menu.Item onClick={() => navigate('/subscriptions')} key="4">
                <Typography style={styles.pricingButton}>Pricing</Typography>
              </Menu.Item>
            }

            <Menu.Item key="3" className="right-menu button-header">
              <div style={styles.avaterContainer}>
                {userContext?.user ? (
                  <>
                    {/* <Avatar
                      shape="square"
                      size={42}
                      style={{
                        marginRight: 20,
                        background: 'transparent',
                        border: '1px solid #FFFFFF44',
                        borderRadius: 8,
                      }}
                      className="cursor-pointer"
                      onClick={() => navigate('/affiliate-program')}
                    >
                      <UsergroupAddOutlined style={{ fontSize: 22 }} />
                    </Avatar> */}
                    <HeaderMenu setShowSettingsModal={setShowSettingsModal} logout={logout} />
                  </>
                ) : (
                  <Button id='start-now-header-button' onClick={() => navigate('/')} style={styles.joinFree} type="primary" className="button">Start Now</Button>
                )}
              </div>
            </Menu.Item>
          </Menu>
          <div style={{ display: 'flex', gap: 17 }}>
            {isMobile && !userContext?.user && <Typography onClick={() => navigate('/')} style={styles.startNowButton}>Start Now</Typography>}
            {isMobile &&
              <div style={{ display: 'flex', alignItems: 'center', gap: isMobile ? 8 : 20, marginRight: isMobile ? 0 : 20 }} key="1">
                {!isGoogleUser && <CreditsTimer isStreams />}
                <CreditsTimer />
              </div>
            }
            <div className="mobile-menu">
              {visible && <Sidebar isMobile />}
              {/* {userContext?.user && <IconHamburger onClick={showDrawer} />} */}
              {userContext?.user && (visible ? <IconClose onClick={toggleDrawer} /> : <IconHamburger onClick={toggleDrawer} />)}
              {/* <Drawer
                title="Menu"
                placement="right"
                closable={true}
                onClose={onClose}
                visible={visible}
              >
                <Menu mode="inline" style={styles.drawerMenu as React.CSSProperties}>
                  {!userContext?.user ? (
                    <Menu.Item key="1" className="button-header">
                      <Button onClick={() => navigate('/')} style={styles.joinFree} type="primary" className="button">Start Now </Button>

                    </Menu.Item>
                  ) : null}
                  {showGoProButton(userContext?.user) &&
                    <Menu.Item className="go-pro-button" key="5" style={{ borderBottom: 'none !important' }}>
                      <div style={styles.upgradeContainerMenu} className="cursor-pointer yellow-hover" onClick={() => navigate('/subscriptions')}>
                        <Typography style={styles.goPro}>Go Pro</Typography>
                        <Image src={RightArrow} preview={false} />
                      </div>
                    </Menu.Item>
                  }
                  {location.pathname === '/' &&
                    <Menu.Item key="2">
                      <Link to="/dashboard">Dashboard</Link>
                    </Menu.Item>
                  }
                  <Menu.Item key="3">
                    <a
                      href={DISCORD_LINK}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Discord
                    </a>
                  </Menu.Item>
                  <Menu.Item key="4">
                    <a
                      href="https://intercom.help/spikes-studio/en/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      FAQ
                    </a>
                  </Menu.Item>
                  <Menu.Item onClick={handleShowSettings} key="5">
                    <Typography>Settings</Typography>
                  </Menu.Item>

                  {!GOOGLE_USER &&
                    <Menu.Item onClick={handleNavigateToStreams} key="7">
                      <Typography>Live Stream AI</Typography>
                    </Menu.Item>
                  }
                  <Menu.Item onClick={handleNavigateToVideoAI} key="8">
                    <Typography>AI Clip Generator</Typography>
                  </Menu.Item>
                  <Menu.Item onClick={handleNavigateToTranscribe} key="9">
                    <Typography>Add subtitles</Typography>
                  </Menu.Item>
                  <Menu.Item onClick={handleNavigateLoayaltyProgram} key="10">
                    <Typography>Loyalty Program</Typography>
                  </Menu.Item>
                  <Menu.Item onClick={handleNavigateLoayaltyProgram} key="11">
                    <Link to="/about">About</Link>
                  </Menu.Item>
                  <Menu.Item onClick={logout} key="12">
                    <Typography>Logout</Typography>
                  </Menu.Item>
                </Menu>
              </Drawer> */}
            </div>
          </div>
          {showSettingsModal && (
            <SettingsModal onClose={() => setShowSettingsModal(false)} />
          )}
          {isOpenHappyHour && (
            <HappyHourModal onClose={() => setIsOpenHappyHour(false)} />
          )}
          {showLoginModal && (
            <LoginModal open={showLoginModal} onClose={() => setShowLoginModal(false)} />
          )}
        </div>
      </Header>
    </div>
  );
};

export const HappyHourTimer = ({ withDiscountText, setHideHappyHour }: { withDiscountText?: boolean, setHideHappyHour?: (is: boolean) => void }) => {
  const [remainingTime, setRemainingTime] = useState(0);
  const [rerender, setRerender] = useState(false);
  const happyHourWasShown = localStorage.getItem('happyHourWasShown');

  setTimeout(() => {
    setRerender(true);
  }, 3000);

  useEffect(() => {
    const interval = setInterval(() => {
      const time = getTimeRangeBetweenCurrentAndGivenDate(JSON.parse(happyHourWasShown!));
      setRemainingTime(time);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [rerender]);

  if (3600 - remainingTime < 0) {
    setHideHappyHour && setHideHappyHour(true);
  }

  return (
    <>{withDiscountText && '50% off '}<div style={{ marginRight: '10px', marginLeft: withDiscountText ? '10px' : 0 }}><Image src={IconTimer} preview={false} width={20} /></div> {secondsToMinutesAndSeconds((3600 - remainingTime))}</>
  );
};

export default HeaderResponsive;
