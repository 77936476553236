import { useMemo } from 'react';
import Space from 'antd/lib/space';
import { Avatar, Badge, Image, Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import { LandingMenu } from '../../menu';

import Logo from '../../../assets/spikes-logo.svg';

export const LandingHeader = () => {
  const styles = useMemo(() => {
    return {
      container: {
        paddingTop: 5,
        width: '100%',
        height: 90,
      },
      hello: {
        fontFamily: 'Poppins',
        fontSize: 36,
        marginBottom: 0,
      },
      loggedInTitle: {
        fontWeight: 200,
      },
      avaterContainer: {
        width: 162,
        marginTop: 5,
        textAlign: 'right',
        position: 'relative',
      },
      avaterBorder: {
        border: '1px solid #67FFCC',
        width: 51,
        height: 50,
        borderRadius: 12,
        position: 'absolute',
        right: -5,
        top: 9,
        filter: 'drop-shadow(0px 0px 8px #67FFCC)',
      },
      avatar: {
        backgroundColor: '#FF2C90',
        verticalAlign: 'middle',
        borderRadius: 8,
        fontFamily: 'Poppins',
        fontSize: 22,
      },
      badge: {
        position: 'absolute',
        top: -22,
        right: -17,
        transform: 'scale(2)',
      },
    } as const;
  }, []);

  return (
    <div style={styles.container} className="space-between align-initial">
      <Space size="large">
        <Image src={Logo} preview={false} height={44} />
        {/* <LandingMenu /> */}
      </Space>
      {/* <div style={styles.avaterContainer} className='cursor-pointer'>
        <div style={styles.avaterBorder}></div>
        <Avatar shape='square' size={40} style={styles.avatar}>R</Avatar>
        <Badge color='#05F29B' style={styles.badge} />
      </div> */}
    </div>
  );
};

export default LandingHeader;
