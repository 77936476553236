import { Space } from 'antd';
import { BrowserView, isBrowser, MobileView } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { Header } from '../../components/header';
import { MainLayout } from '../../layouts';
import { TermsCard } from '../../components/storage-meter/terms-card';
import { Helmet } from 'react-helmet';
import { STRUCTURED_DATA_TERMS_PAGE } from '../../structured-data';

const TermsPage = () => {
  const { pathname, state } = useLocation();

  return (
    <>
      <Helmet>
        <title>Spikes Studio | Terms & Conditions</title>
        <link rel="canonical" href="https://spikes.studio/terms" />
        <script type="application/ld+json">
          {JSON.stringify(STRUCTURED_DATA_TERMS_PAGE)}
        </script>
      </Helmet>
      <MainLayout
        header={isBrowser ? <Header title={'Terms And Conditions'} /> : null}
      >
        <Space direction="vertical" size="large" className="w-100">
          <TermsCard />
        </Space>
      </MainLayout>
    </>
  );
};

export default TermsPage;
