import React, { useContext, useState, useEffect, useMemo } from 'react';
import {
  Button,
  Image,
  Layout,
  Typography,
  message,
} from 'antd';

import { useNavigate } from 'react-router-dom';


import { postAudit } from '../../services/audit.service';
import { EAuditAction } from '../../data/intefaces/audit.interface';

import axios from 'axios';

import { HeaderResponsive } from '../../components/header/header-responsive';
import { FooterResponsive } from '../../components/footer';
import { UserContext } from '../../data/userContext';

import './ReferralPage.less';
import { COLORS } from '../../themes/colors';
import IconCopy from '../../assets/copy-referral.svg';
import InputAsset from '../../assets/referral-asset.svg';
import IconTwitter from '../../assets/icon-footer-twitter.svg';
import IconInstagram from '../../assets/icon-footer-instagram.svg';
import IconYouTube from '../../assets/icon-footer-youtube.svg';
import IconDiscord from '../../assets/icon-footer-discord.svg';
import IconTikTok from '../../assets/icon-footer-tiktok.svg';

import FirstPartners from '../../assets/referral-first-image.svg';
import SecondPartners from '../../assets/referral-second-image.svg';

import { ReactComponent as FirstPartnersDark } from '../../assets/referral-first-image-dark.svg';
import { ReactComponent as SecondPartnersDark } from '../../assets/referral-second-image-dark.svg';
import { ArrowLeftOutlined } from '@ant-design/icons';
import SwitchButton from '../../components/buttons/switch/SwitchButton';

import { MainLayout } from '../../layouts';
import { isBrowser } from 'react-device-detect';
import { Header } from '../../components/header';
import { Footer as AppFooter } from '../../components/footer';
import { DISCORD_LINK } from '../../constants/content-constants';

const { Content } = Layout;


const ReferralPage = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [isActiveAffiliate, setIsActiveAffiliate] = useState(false);
  postAudit({
    user_action: EAuditAction.AuditActionLogin
  });

  const isAffiliateUser = userContext?.user?.is_affiliate;

  const styles = useMemo(() => {
    return {
      main: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      communitySection: {
        background: '#3A3A3A',
        borderRadius: '8px',
        // height: 350,
        width: 350,
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      },
      communityIconsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 8,
        justifyContent: 'center'
      },
      title: {
        fontSize: 24,
        fontWeight: 300,
        marginBottom: 10,
        lineHeight: '42px',
        marginTop: 26,
        color: 'var(--text-brand-primary)',
      },
      iframeText: {
        fontSize: 32,
        color: 'var(--text-base)',
        fontWeight: 400
      }
    } as const;
  }, []);

  useEffect(() => {
    const updateUser = async () => {
      try {
        const response = await axios.get('streamer/profile');
        if (response?.status === 200) {
          userContext?.setUser(response.data);
        }
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
      }
    };

    updateUser();
  }, []);

  // const invitationUrl = `https://${window.location.hostname.replace(
  //   'www.',
  //   '',
  // )}/?ref=${userContext?.user?.referral_key}`;
  const invitationUrl = `${process.env.REACT_APP_HOMEPAGE_URL}/?ref=${userContext?.user?.referral_key}`;


  const handleCopyClick = () => {
    if (invitationUrl) {
      navigator.clipboard
        .writeText(invitationUrl)
        .then(() => {
          message.success('Referral link copied to clipboard');
        })
        .catch((error) => {
          message.error('Referral link Failed to copy' + { error });
        });
    }
  };


  const handleAffiliate = () => {
    window.open('https://www.notion.so/Earn-money-as-a-Spikes-Studio-Affiliate-Partner-d252a0795f1f484d8338eabe387953f9', '_blank');
  };

  const successfulInvites = userContext?.user?.referral_total_register_bonus || 0;

  return (
    <MainLayout
      header={isBrowser ? <Header title={''} /> : null}
      footer={<AppFooter shadow={false} />}
      marginTop='26px'
    >
      <Content className="general-content-wrapper referral-page subscriptions-page">
        <div style={styles.main}>
          <div>
            <div className='title'>Invite your Friends!</div>
            <div className="subtitle">Earn a reward for each friend who joins us.</div>
            <div className="content-wrapper">
              <div className="content-column">
                <div className="block">
                  <Typography className="info-title">{isActiveAffiliate ? 'Subscriptions' : 'Free Minutes'}</Typography>
                  <Typography className="counter">{isActiveAffiliate ? (userContext?.user?.affiliate_total_subscriptions || 0) : successfulInvites * 20}</Typography>
                </div>
                <div className="block">
                  <Typography className="info-title">{isActiveAffiliate ? 'Money earned' : 'Successful Invites'}</Typography>
                  <Typography style={isActiveAffiliate ? { fontSize: 48, color: '#66ffcc', opacity: '0.7' } : {}} className="counter">{isActiveAffiliate ? <>${userContext?.user?.affiliate_revenue || 0}</> : successfulInvites}</Typography>
                </div>
              </div>
              <div className="block core-block">
                <Typography className="core-title">{isActiveAffiliate ? 'Maximize your earnings by inviting all your leads' : <> Get an extra <span style={{ color: 'var(--text-brand-primary)' }}>20</span> minutes and your friend receives a <span style={{ color: 'var(--text-brand-primary)' }}>20% off</span> coupon!</>}</Typography>
                {/* <Typography className="core-subtitle">{isActiveAffiliate ? <>To recieve your Affiliate coupon code fill out <span style={{ color: COLORS.PRIMARY, textDecoration: 'underline' }}><a href="https://form.typeform.com/to/dkAsnfdo" target="_blank" rel="noreferrer">this form</a>
                </span></> : 'Promotion will last until the end of the month'}</Typography> */}

                <div className="core-divider" > {isActiveAffiliate && <span ><a style={{ color: '#CBCBCB' }} href="https://affiliate-spikes-studio.notion.site/Earn-money-as-a-Spikes-Studio-Affiliate-Partner-d252a0795f1f484d8338eabe387953f9" target="_blank" rel="noreferrer">Learn More</a></span>} </div>


                <div className="input-wrapper">
                  <div className="input">
                    <Typography className="input-text">{invitationUrl}</Typography>
                    <div onClick={handleCopyClick} className="copyBox">
                      <Image src={IconCopy} preview={false} />
                    </div>
                  </div>
                  <div className="input-asset">
                    <Image src={InputAsset} preview={false} />
                  </div>
                </div>
                <div style={styles.column}>
                  <Typography className="community-section__title" style={styles.title}>
                    Share it on your socials
                  </Typography>
                  <div style={styles.communityIconsContainer}>
                    {/* <SocialIconItem icon={IconDiscord} link={DISCORD_LINK} /> */}
                    <SocialIconItem icon={IconYouTube} link='https://www.youtube.com/@SpikesStudio' />
                    <SocialIconItem icon={IconInstagram} link='https://www.instagram.com/spikes_studio' />
                    <SocialIconItem icon={IconTikTok} link='https://www.tiktok.com/@spikesstudio' />
                    <SocialIconItem icon={IconTwitter} link='https://twitter.com/Spikes_St' />
                  </div>
                </div>
              </div>
            </div>
            {successfulInvites >= 5 && !isActiveAffiliate &&
              <div className="affiliate-button-wrapper">
                <div onClick={handleAffiliate} className="affiliate-button">
                  Become an Affiliate
                </div>
              </div>
            }

          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 28, marginTop: 30 }}>
          <Typography style={styles.iframeText}>More than 500.000 content creators across 90 countries</Typography>
          {/* <iframe
            style={{ border: 'none' }}
            src="https://cards.producthunt.com/cards/testimonials/667302,666655,667154,666831,667237,666530"
            width="1000px"
            height="1000px"
            frameBorder={0}
            scrolling="no"
            allowFullScreen={false}
          /> */}
        </div>


        <div className='referral-partners' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 60, marginTop: 30, gap: 20 }}>
          {/* <FirstPartners />
          <SecondPartners /> */}
          <div className='referral-partners__container light'>
            <FirstPartnersDark />
            <SecondPartnersDark />
          </div>
          <div className='referral-partners__container dark'>
            <Image src={FirstPartners} preview={false} />
            <Image src={SecondPartners} preview={false} />
          </div>
        </div>
      </Content>
      <FooterResponsive />
    </MainLayout>
  );
};

const SocialIconItem = ({ icon, link }: { icon: string, link: string }) => {
  const styles = useMemo(() => {
    return {
      iconContainer: {
        backgroundColor: '#373636',
        padding: 3,
        height: 42,
        width: 42,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
      }
    } as const;
  }, []);
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <div className="icon-container" style={styles.iconContainer}>
        <Image
          src={icon}
          height={28}
          preview={false}
          className="cursor-pointer"
        />
      </div>
    </a>
  );
};

interface IReferralToggleProps {
  isActiveAffiliate: boolean;
  setIsActiveAffiliate: (is: boolean) => void;
  disabled?: boolean;
}

const ReferralToggle = ({
  isActiveAffiliate,
  setIsActiveAffiliate,
  disabled = false
}: IReferralToggleProps) => {
  const [messageApi, contextHolder] = message.useMessage();

  const handleToast = () => {
    messageApi.warning('To proceed, please apply for our affiliate program. Click the <a href="https://example.com/affiliate-program">Apply Now</a> button located in the homepage footer.', 5);
    handleToast
  };
  const handleRefferalToggle = (value: boolean) => {
    setIsActiveAffiliate(value)
  };

  const styles = useMemo(() => {
    return {
      root: {
        display: 'flex',
        flexDirection: 'column',
        gap: '18px',
        alignItems: 'center',
        marginBottom: 25,
      },
      container: {
        display: 'flex',
        gap: 15,
        alignItems: 'center'
      },
      button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.GRAY_CONTROLS,
        width: 144,
        height: 40,
        borderRadius: 4,
        cursor: 'pointer',
        position: 'relative'
      },
      activeButton: {
        border: `1px solid ${COLORS.GRAY_DISABLED}`,
        color: COLORS.PRIMARY
      },
      buttonText: {
        fontSize: 18,
        fontWeight: 400,
        userSelect: 'none'
      },
      discount: {
        borderRadius: '4px',
        padding: '3px 13px',
        background: COLORS.YELLOW,
        position: 'absolute',
        bottom: '-26px',
        zIndex: 15,
      },
      discountText: {
        color: COLORS.BLACK,
        fontSize: '12px',
        fontWeight: 600,
      },
      billText: {
        fontSize: 14,
        fontWeight: 400,
        color: COLORS.WHITE
      },
      saveText: {
        fontSize: 18,
        fontWeight: 400
      },
      arrow: {
        position: 'absolute',
        right: -25,
        top: -12,
      }
    } as const;
  }, []);


  return (
    <div style={styles.root}>
      {contextHolder}
      <div style={styles.container}>
        <Typography style={styles.billText}>Referral Page</Typography>
        <SwitchButton
          value={isActiveAffiliate}
          onChange={(value: boolean) => handleRefferalToggle(value)}
        />
        <Typography style={styles.billText}>Affiliate Page</Typography>
      </div>
    </div>
  );
};


export default ReferralPage;
