export const globalStyles = () => {
  return {
    helpTooltip: {
      background: '#001922',
      borderRadius: 12,
      border: '1px solid #67FFCC',
      fontSize: 12,
      lineHeight: '16px',
      textAlign: 'center',
    },
  } as const;
};
