import React, { useState, useMemo, useEffect, useContext } from 'react';
import { Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import './GifsAnimationsTab.less';
import { PricingModal } from '../../../components/pricing-modal/PricingModal';
import { getEnabledGifsForUser } from '../../../utils';
import { ReactComponent as IconSearch } from '../../../assets/input-search.svg';
import { UserContext } from '../../../data/userContext';
import { useFocus } from '../Editor.page';

export interface IGifsAnimationTab {
  handleChooseGif: (url: string, name: string, ratio: number) => void;
}

interface SearchResult {
  url: string;
  height: number;
  width: number;
  name: string;
}

const SUBSCRIBE_GIF = {
  url: 'https://media4.giphy.com/media/CxRAg9HWoRZv3dvm0O/giphy.gif?cid=1653bcdbe80p8wf20hi9b88fa3v9ev3zv23wxbtmdchht7m7&ep=v1_gifs_gifId&rid=giphy.gif&ct=s',
  name: 'subscribe',
  height: 324,
  width: 480
}

const GifsAnimationsTab = ({ handleChooseGif }: IGifsAnimationTab) => {
  const [searchText, setSearchText] = useState('');
  const [firstLoad, setFirstLoad] = useState(true);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [isPricingModalOpened, setIsPricingModalOpened] = useState(false);
  const userContext = useContext(UserContext);
  const [inputRef, setInputFocus] = useFocus();

  const handleOnInputClick = (e: any) => {
    e.preventDefault();
    inputRef.current.blur();
    setInputFocus();
  }

  useEffect(() => {
    if (firstLoad) {
      // Make the initial request with "funny" as the query on first load
      handleGifsSearch('funny');
      setFirstLoad(false);
    } else {
      // Set up a debounce for subsequent searches
      const debounceTimeout = setTimeout(() => {
        handleGifsSearch(searchText || 'funny');
      }, 700);
  
      // Clear the timeout if searchText changes before the debounce completes
      return () => clearTimeout(debounceTimeout);
    }
  }, [searchText]);

  const handleGifsSearch = async (searchInfo: string) => {
    try {
      setLoading(true);
      const apiUrl = '/streamer/search-giphy';
      const requestData = { query: searchInfo };

      const response = await axios.post(apiUrl, requestData);
      const gifResults: SearchResult[] = response.data;

      if (searchInfo === 'funny' || searchInfo.toLowerCase().includes('subs')) {
        gifResults.unshift(SUBSCRIBE_GIF);
      }

      setSearchResults(gifResults);
    } catch (error) {
      console.error('Error searching for images:', error);
    } finally {
      setLoading(false);
    }
  };

  const gifsTabEnabledForUser = getEnabledGifsForUser(userContext?.user);

  const handleOpenPricingModal = () => {
    setIsPricingModalOpened(true);
  };

  const handleClosePricingModal = () => {
    setIsPricingModalOpened(false);
  };

  const handleClick = (element: SearchResult) => {
    if (!gifsTabEnabledForUser) {
      handleOpenPricingModal();
      return;
    }
    const ratio = element.width / element.height;
    handleChooseGif(element.url, element.name, ratio);
  };

  const styles = useMemo(() => {
    return {
      container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'var(--base)'
      },
      searchContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      searchBox: {
        display: 'flex',
        width: '100%',
        maxWidth: '335px',
        background: '#797979',
        height: '24px',
        borderRadius: '15px',
        marginTop: '20px'
      },
      searchInput: {
        flex: '1',
        color: '#D0C9C9',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '21.411px',
        border: 'none',
        outline: 'none',
        background: 'transparent',
        textAlign: 'center',
      },
      imagesContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: '5px',
        width: '100%',
        padding: '15px',
        overflowY: 'auto',
        minHeight: '419px',
      },
      imageBox: {
        width: '95px',
        height: '95px',
        margin: '10px',
        background: '#404040',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '21px',
        cursor: 'pointer',
        animationName: 'fadeIn',
        animationDuration: '3.5s',
        animationTimingFunction: 'ease',
      },
    } as const;
  }, []);

  return (
    <div className='subtitles-wrapper b-roll-tab scrollbar'>
      <PricingModal isOpen={isPricingModalOpened} handleClosePricingModal={handleClosePricingModal} />
      <div style={styles.searchContainer}>
        <div className='search-box'>
          <IconSearch />
          <input
            ref={inputRef}
            onClick={handleOnInputClick}
            type="text"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="search-input"
          />
        </div>
        {loading ? (
          <div style={styles.imagesContainer}>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 64, color: '#FFF' }}
                    spin
                  />
                }
              />
            </div>
          </div>
        ) : (
          <div className='editor-grid'>
            {searchResults.map((result, index) => (
              <div
                key={index}
                className='editor-grid__item transparent-background'
                onClick={() => handleClick(result)}
              >
                <img
                  src={result.url}
                  alt={`Image ${index}`}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default GifsAnimationsTab;
