import React from 'react';
import { Typography, Row, Col, Layout } from 'antd';
import './MarketingExperts.less';
import TemplateBanner from './../../assets/template-banner1.png';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

import FooterResponsive from '../../components/footer/FooterResponsive';
import HeaderResponsive from '../../components/header/header-responsive/HeaderResponsive';

const MarketingExpertsPage: React.FC = () => {
  return (
    <Layout
      className="layout"
      style={{
        minHeight: '100vh',
        background: '#080808',
      }}
    >
      <HeaderResponsive />
      <Content className="marketing-experts-page">
        <div className="marketing-experts-header">
          <Title level={2}>
            Supercharge Your Marketing Efforts with Spikes
          </Title>
        </div>

        <div className="marketing-experts-section">
          <Title level={3}>Introduction:</Title>
          <Paragraph>
            Are you a marketing expert looking for innovative ways to create
            compelling content and engage your target audience? Look no further
            than Spikes. Our AI-powered platform is designed to take your
            marketing efforts to new heights, helping you captivate your
            audience and drive results.
          </Paragraph>
        </div>

        <div className="marketing-experts-section">
          <Title level={3}>Case Story:</Title>
          <Paragraph>
            Meet Alex, a marketing expert who is constantly seeking fresh ways
            to promote products and engage customers. With Spikes, Alex
            discovers a treasure trove of tools and features to enhance their
            marketing campaigns. They can upload their own videos or search for
            popular Twitch and YouTube clips.
          </Paragraph>
        </div>
        <div className="marketing-experts-section">
          <Paragraph>
            Using Spikes versatile clip editor, Alex effortlessly trims and
            edits videos or curates engaging clips from Twitch and YouTube. This
            flexibility allows them to tap into diverse content sources,
            creating impactful marketing content that tells their brand story.
          </Paragraph>
        </div>
        <div className="marketing-experts-section">
          <Paragraph>
            Spikes automatically generates subtitles, enhancing accessibility
            and reach. With the smart word highlighter feature, Alex can
            emphasize key phrases, boosting viewer attention and optimizing
            marketing messages.
          </Paragraph>
        </div>
        <div className="marketing-experts-section">
          <Paragraph>
            The intelligent filter enables Alex to easily search and curate the
            most relevant and impactful content from their own uploads or
            popular clips. They can filter by emotions or specific keywords,
            saving time and driving audience resonance.
          </Paragraph>
        </div>
        <div className="marketing-experts-section">
          <Paragraph>
            Spikes offers up to 500 GB storage for secure organization and easy
            access to marketing assets, including uploaded videos and curated
            clips.
          </Paragraph>
        </div>
        <div className="marketing-experts-section">
          <Row justify="center">
            <Col xs={24} sm={24} md={24}>
              <div className="image-container">
                <img
                  src={TemplateBanner}
                  alt="marketing-experts us"
                  className="marketing-experts-image"
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="marketing-experts-section">
          <Title level={3}>Conclusion:</Title>
          <Paragraph>
            With Spikes, marketing experts like Alex can supercharge their
            efforts. By uploading videos or searching for popular Twitch and
            YouTube clips, utilizing automated subtitles, and leveraging the
            intelligent filter, they can create compelling content that engages
            their audience. Join Alex and experience the transformative power of
            Spikes in your marketing endeavors.
          </Paragraph>
        </div>
      </Content>
      <FooterResponsive />
    </Layout>
  );
};

export default MarketingExpertsPage;
