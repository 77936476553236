export interface IAudit {
  user_action: EAuditAction
  stream?: string
  clip?: string
  details?: any
}

export enum EAuditAction {
  AuditActionDownload = 'download',
  AuditActionRefresh = 'refresh',
  AuditActionLogin = 'login',
  AuditActionMobileLogin = 'mobile_login',
  AuditActionCopyDownloadUrl = 'copy_download_url',
  AuditActionShareFacebook = 'share_facebook',
  AuditActionShareTwitter = 'share_twitter',
  AuditActionCopySpikeTimestamps = 'copy_spike_timestamps',
  AuditActionCopyTwitchPlatormUrl = 'copy_twitch_platform_url'
}