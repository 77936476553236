// TableComparison.tsx
import React from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { CheckOutlined, CloseOutlined, CheckCircleOutlined } from '@ant-design/icons';
import './TableComparison.less';

interface DataType {
  key: React.Key;
  comparedFeatures: string | JSX.Element; // Update type to allow JSX.Element
  basic: boolean | string;
  proPlus: boolean | string;
  enterprise: boolean | string;
  description: string;
}


const isRowExpandable = (record: DataType) => {
  // Make only rows with keys 'Key 10', 'Key 12', 'Key 13', and 'Key 14' non-expandable
  return record.key !== 'Key 24' && record.key !== 'Key 19' && record.key !== 'Key 16' && record.key !== 'Key 10' && record.key !== 'Key 2' && record.key !== 'Key 1' && record.key !== 'Key 20';
};

const getClassForRecord = (record: DataType) => {
  if (record.key === 'Key 24' || record.key === 'Key 19' || record.key === 'Key 16' || record.key === 'Key 10') {
    return 'category-row';
  }
  return '';
};

const columns: ColumnsType<DataType> = [
  {
    title: (
      <p style={{ color: 'var(--text-base)', fontSize: '20px', fontFamily: 'Poppins' }}>
      </p>
    ),
    dataIndex: 'comparedFeatures',
    key: 'comparedFeatures',
    width: '25%',
    align: 'left',
    ellipsis: true,
    render: (text) => (
      <p style={{ color: 'var(--text-base)', fontSize: '14px', fontFamily: 'Poppins' }}>
        {text}
      </p>
    ),
    // Make this column stick
  },
  {
    title: (
      <p style={{ color: 'var(--text-base)', fontSize: '20px', fontFamily: 'Poppins', fontWeight: 600 }}>
        Basic
      </p>
    ),
    dataIndex: 'basic',
    key: 'basic',
    width: '25%',
    align: 'center',
    render: (value) =>
      value === true ? (
        <CheckCircleOutlined style={{ color: 'green' }} />
      ) : value === false ? (
        <CloseOutlined style={{ color: 'red' }} />
      ) : (
        <span style={{ color: 'var(--text-base)', fontSize: '14px', fontFamily: 'Poppins' }}>
          {value}
        </span>
      ),
  },
  {
    title: (
      <p style={{ color: 'var(--text-base)', fontSize: '20px', fontFamily: 'Poppins', fontWeight: 600 }}>
        PRO+
      </p>
    ),
    dataIndex: 'proPlus',
    key: 'proPlus',
    width: '25%',
    align: 'center',
    render: (value) =>
      value === true ? (
        <CheckCircleOutlined style={{ color: 'green' }} />
      ) : value === false ? (
        <CloseOutlined style={{ color: 'red' }} />
      ) : (
        <span style={{ color: 'var(--text-base)', fontSize: '14px', fontFamily: 'Poppins' }}>
          {value}
        </span>
      ),
  },
  {
    title: (
      <p style={{ color: 'var(--text-base)', fontSize: '20px', fontFamily: 'Poppins', fontWeight: 600 }}>
        Enterprise
      </p>
    ),
    dataIndex: 'enterprise',
    key: 'enterprise',
    width: '25%',
    align: 'center',
    render: (value) =>
      value === true ? (
        <CheckCircleOutlined style={{ color: 'green' }} />
      ) : value === false ? (
        <CloseOutlined style={{ color: 'red' }} />
      ) : (
        <span style={{ color: 'var(--text-base)', fontSize: '14px', fontFamily: 'Poppins' }}>
          {value}
        </span>
      ),
  },
];





const data: DataType[] = [
  {
    key: 'Key 1',
    comparedFeatures: 'Available minutes to upload',
    basic: '30 mins monthly',
    proPlus: 'from 100 minutes a month',
    enterprise: 'from 1000 minutes a month',
    description: '',
  },
  {
    key: 'Key 2',
    comparedFeatures: 'Watermark',
    basic: false,
    proPlus: true,
    enterprise: true,
    description: '',
  },
  {
    key: 'Key 3',
    comparedFeatures: 'Clip Resolution',
    basic: '720p',
    proPlus: '1080p',
    enterprise: '1080p',
    description: 'Quality of the video output.',
  },
  {
    key: 'Key 4',
    comparedFeatures: 'Auto-Captions',
    basic: true,
    proPlus: true,
    enterprise: true,
    description: 'Automated generation of captions for videos.',
  },
  {
    key: 'Key 5',
    comparedFeatures: 'Animated Captions',
    basic: 'Limited',
    proPlus: true,
    enterprise: true,
    description: 'Eye-catching caption animations for social media engagement.',
  },
  {
    key: 'Key 6',
    comparedFeatures: 'Caption Style Selection',
    basic: 'Limited',
    proPlus: true,
    enterprise: true,
    description: 'Options for customizing the style of captions.',
  },
  {
    key: 'Key 7',
    comparedFeatures: 'B-roll',
    basic: 'Limited',
    proPlus: true,
    enterprise: true,
    description: 'Incorporation of additional footage.',
  },
  {
    key: 'Key 8',
    comparedFeatures: 'GIF B-roll',
    basic: false,
    proPlus: true,
    enterprise: true,
    description: 'Use of GIFs as supplementary footage.',
  },
  {
    key: 'Key 9',
    comparedFeatures: 'Audio & SFX Insertion',
    basic: false,
    proPlus: true,
    enterprise: true,
    description: 'Adding sound effects and audio tracks.',
  },
  {
    key: 'Key 10',
    comparedFeatures: (
      <p style={{ fontSize: '20px', color: 'var(--text-base)' }}>
        AI and Automation
      </p>
    ),
    basic: '',
    proPlus: '',
    enterprise: '',
    description: '',
  },
  {
    key: 'Key 11',
    comparedFeatures: 'AI Curation Virality Score',
    basic: true,
    proPlus: true,
    enterprise: true,
    description: 'AI assessment of potential virality of clips.',
  },
  {
    key: 'Key 13',
    comparedFeatures: 'AI-Generated B-roll',
    basic: false,
    proPlus: true,
    enterprise: true,
    description: 'Incorporation of additional footage.',
  },
  {
    key: 'Key 14',
    comparedFeatures: 'Auto Reframe',
    basic: true,
    proPlus: true,
    enterprise: true,
    description: 'Automated adjustment of video framing.',
  },
  {
    key: 'Key 15',
    comparedFeatures: 'AI-Generated Emojis',
    basic: true,
    proPlus: true,
    enterprise: true,
    description: 'AI assistance in selecting relevant emojis.',
  },
  {
    key: 'Key 16',
    comparedFeatures: (
      <p style={{ fontSize: '20px', color: 'var(--text-base)' }}>
        Social Media Integration
      </p>
    ),
    basic: '',
    proPlus: '',
    enterprise: '',
    description: '',
  },
  {
    key: 'Key 17',
    comparedFeatures: 'Direct Sharing to Social Media',
    basic: false,
    proPlus: true,
    enterprise: true,
    description: 'Capability to post directly to social platforms.',
  },
  {
    key: 'Key 18',
    comparedFeatures: 'Social Media Scheduler',
    basic: false,
    proPlus: true,
    enterprise: true,
    description: 'Tool for scheduling posts on social media.',
  },
  {
    key: 'Key 19',
    comparedFeatures: (
      <p style={{ fontSize: '20px', color: 'var(--text-base)', }}>
        Customization and Branding
      </p>
    ),
    basic: '',
    proPlus: '',
    enterprise: '',
    description: '',
  },
  {
    key: 'Key 20',
    comparedFeatures: 'Account Manager',
    basic: false,
    proPlus: false,
    enterprise: true,
    description: '',
  },
  {
    key: 'Key 21',
    comparedFeatures: 'Custom Brand Templates',
    basic: false,
    proPlus: true,
    enterprise: true,
    description: 'Branded templates for consistent look and feel.',
  },
  {
    key: 'Key 22',
    comparedFeatures: 'Text Insertions',
    basic: false,
    proPlus: true,
    enterprise: true,
    description: 'Ability to add text overlays to clips.',
  },
  {
    key: 'Key 23',
    comparedFeatures: 'Fonts & Templates',
    basic: true,
    proPlus: true,
    enterprise: true,
    description: 'Variety of fonts and templates available for use.',
  },
  {
    key: 'Key 24',
    comparedFeatures: (
      <p style={{ fontSize: '20px', color: 'var(--text-base)', }}>
        Storage and Access
      </p>
    ),
    basic: '',
    proPlus: '',
    enterprise: '',
    description: '',
  },
  {
    key: 'Key 25',
    comparedFeatures: 'Storage Lifespan',
    basic: '7 days',
    proPlus: '90 days',
    enterprise: 'Unlimited',
    description: 'Storage capacity for saving clips.',
  },
  {
    key: 'Key 26',
    comparedFeatures: 'Premium Editor',
    basic: 'Limited',
    proPlus: true,
    enterprise: true,
    description: 'Access to advanced editing features.',
  },
  {
    key: 'Key 27',
    comparedFeatures: 'Mobile Editor',
    basic: false,
    proPlus: true,
    enterprise: true,
    description: 'Availability of a mobile app for editing on the go.',
  },
  {
    key: 'Key 28',
    comparedFeatures: 'Early Access to New Features',
    basic: false,
    proPlus: true,
    enterprise: true,
    description: 'Priority access to new tool features',
  },
];

const expandedRowRender = (record: DataType) => (
  <p style={{ margin: 0, color: 'var(--text-base)' }}>{record.description}</p>
);

const TableComparison: React.FC = () => (
  <div className="table-container table-comparison">
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      expandable={{
        expandedRowRender,
        indentSize: 515, // Adjust the value to your desired indentation size
        rowExpandable: isRowExpandable,
      }}
      rowClassName={(record) => getClassForRecord(record)}
      style={{ width: '100%', margin: '0', color: 'var(--text-base)' }}
    />
  </div>
);


export default TableComparison;


