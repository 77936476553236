import React, { useState } from 'react';
import { Col, Layout, Row, Spin } from 'antd';
import { HeaderResponsive } from '../../components/header/header-responsive';
import { FooterResponsive } from '../../components/footer';
import Tutorial1 from '../../assets/1.png';
import Tutorial2 from '../../assets/2.png';
import Tutorial3 from '../../assets/3.png';
import Tutorial4 from '../../assets/4.png';
import Tutorial5 from '../../assets/5.png';
import Tutorial6 from '../../assets/6.png';
import Tutorial7 from '../../assets/7.png';
import Tutorial8 from '../../assets/8.png';
import Tutorial9 from '../../assets/9.png';
import Tutorial10 from '../../assets/10.png';
import Tutorial11 from '../../assets/11.png';
import Tutorial12 from '../../assets/12.png';
import Tutorial13 from '../../assets/13.png';
import Tutorial14 from '../../assets/14.png';
import Tutorial15 from '../../assets/15.png';
import './Tutorial.less';
import ReactModal from 'react-modal';
import YouTube from 'react-youtube';
import { useWindowDimensions } from '../Editor/Editor.page';
import { LoadingOutlined } from '@ant-design/icons';
import { MainLayout } from '../../layouts';
import { isBrowser } from 'react-device-detect';
import { Header } from '../../components/header';
import { Footer as AppFooter } from '../../components/footer';

const { Content } = Layout;

const TUTORIAL_ITEMS = [
  {
    videoUrl: 'https://youtu.be/a3-L3flr5aU?si=InKgZOVoWvLDjZ65',
    thumbnail: Tutorial1,
  },
  {
    videoUrl: 'https://youtu.be/m__KAlKUzFI?si=qD7uIf7OIt75mZbV',
    thumbnail: Tutorial2,
  },
  {
    videoUrl: 'https://youtu.be/UjKA-OtfM_Y?si=d3_yFFOSdEMRHpQi',
    thumbnail: Tutorial3,
  },
  {
    videoUrl: 'https://youtu.be/4XYdrUIAZ4I?si=AiODFOdNW0Rds4_G',
    thumbnail: Tutorial4,
  },
  {
    videoUrl: 'https://youtu.be/REEm5jgKFt0?si=oCe9JrhFavM1okfc',
    thumbnail: Tutorial5,
  },
  {
    videoUrl: 'https://youtu.be/b_QdIsWnXBY?si=4hM-s466OrzYD1B0',
    thumbnail: Tutorial6,
  },
  {
    videoUrl: 'https://youtu.be/ZXvXIlLS_3Y?si=v8vucXG0fklF_G7X',
    thumbnail: Tutorial7,
  },
  {
    videoUrl: 'https://youtu.be/ZfcEdcBtHxk?si=TzZvvVMNJ4YNMUdk',
    thumbnail: Tutorial8,
  },
  {
    videoUrl: 'https://youtu.be/vDBZEZ_xZ8M?si=LB3e598HEjectVdh',
    thumbnail: Tutorial9,
  },
  {
    videoUrl: 'https://youtu.be/qDKQuOSv20M?si=Zf_U2bNxm9uY1rhk',
    thumbnail: Tutorial10,
  },
  {
    videoUrl: 'https://youtu.be/u6NAxqFb218?si=LY3Qmlzv7D4MXSC4',
    thumbnail: Tutorial11,
  },
  {
    videoUrl: 'https://youtu.be/M8gTxIqRCFY?si=dJvde0TQLMcsiAzm',
    thumbnail: Tutorial12,
  },
  {
    videoUrl: 'https://youtu.be/7L8EDIlxXAM?si=ItWFNc8iDWc0cSnf',
    thumbnail: Tutorial13,
  },
  {
    videoUrl: 'https://youtu.be/HmS2M1Ylnmo?si=3mbQkRXp3RC4-vRR',
    thumbnail: Tutorial14,
  },
  {
    videoUrl: 'https://youtu.be/wMxAWeQfYyA?si=EdeC1rY02ugW6AKb',
    thumbnail: Tutorial15,
  },
];

const getYoutubeVideoId = (url: string) => {
  const regExp = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
  const match = url.match(regExp);
  return match ? match[1] : null;
};

const Tutorial = () => {
  const { width } = useWindowDimensions();
  const isMobile = width < 920;

  return (
    <>
      <MainLayout
        header={isBrowser ? <Header title={''} /> : null}
        footer={<AppFooter shadow={false} />}
        marginTop='26px'
      >
        <Content className="general-content-wrapper">
          <div style={{ fontSize: isMobile ? 24 : 38 }} className="tab-name-heading">
            <span>Tutorials</span>
          </div>
          <Row
            style={{ width: '100%', marginTop: 50, marginLeft: 'auto', marginRight: 'auto' }}
            gutter={[48, 56]}
          >
            {TUTORIAL_ITEMS.map((item) => (
              <Col key={item.videoUrl} xs={24} md={12} lg={8}>
                <TutorialCard thumbnail={item.thumbnail} videoUrl={item.videoUrl} />
              </Col>
            ))}
          </Row>
        </Content>
        <FooterResponsive />
      </MainLayout>
    </>
  );
};

const TutorialCard = ({ videoUrl, thumbnail }: { videoUrl: string; thumbnail: any }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const openModal = () => {
    setTimeout(() => {
      if (!isModalOpen) {
        setIsModalOpen(true);
      }
    }, 100);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
  };

  const videoId = getYoutubeVideoId(videoUrl);

  return (
    <div onClick={openModal} className="tutorial-card">
      {isLoading &&
        <div className="loader-tutorial">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 42, color: 'rgb(110, 110, 110)' }} spin />} />
        </div>
      }
      <img
        src={thumbnail}
        alt="thumbnail"
        onLoad={handleImageLoad}
        onError={handleImageError}
        style={{ display: isLoading ? 'none' : 'block' }}
      />
      {isModalOpen && <VideoModal isOpen={isModalOpen} onClose={closeModal} videoId={videoId} />}
    </div>
  );
};

const VideoModal = ({ isOpen, onClose, videoId }: any) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 920;

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: 0,
          border: 'none',
          background: 'none',
          paddingTop: 30,
          paddingRight: 30,
        },
      }}
    >
      <div onClick={onClose} className="close-tutorial-modal">
        X
      </div>

      <YouTube
        videoId={videoId}
        opts={{
          width: isMobile ? '280px' : '1020px',
          height: isMobile ? '190px' : '580px',
          margin: '100px',
        }}
      />
    </ReactModal>
  );
};

export default Tutorial;
