import React, { useState, useMemo, useContext } from 'react';
import TiktokElements from '../../../../assets/tiktok_elements.svg';
import TwitterElements from '../../../../assets/twitter_elements.svg';
import TwitchElements from '../../../../assets/twitch_elements.svg';
import YoutubeElements from '../../../../assets/youtube_elements.svg';
import InstagramElements from '../../../../assets/instagram_elements.svg';
import FacebookElements from '../../../../assets/facebook_elements.svg';
import { ReactComponent as IconTiktok } from '../../../../assets/social_tiktok.svg';
import { ReactComponent as IconInstagram } from '../../../../assets/social_instagram.svg';
import { ReactComponent as IconFacebook } from '../../../../assets/social_facebook.svg';
import { ReactComponent as IconTwitter } from '../../../../assets/social_twitter.svg';
import { ReactComponent as IconTwitch } from '../../../../assets/social_twitch.svg';
import { ReactComponent as IconYoutube } from '../../../../assets/social_youtube.svg';
import { getEnabledElementsForUser } from '../../../../utils';
import { UserContext } from '../../../../data/userContext';
import { PricingModal } from '../../../../components/pricing-modal/PricingModal';
import { useFocus } from '../../Editor.page';

export interface IChooseSocialLogoTab {
  handleChooseLogo: (text: string, name: string, ratio: number) => void;
}

const SocialLogosTab = ({ handleChooseLogo }: IChooseSocialLogoTab) => {
  const [isPricingModalOpened, setIsPricingModalOpened] = useState(false);
  const userContext = useContext(UserContext);

  const socialMediaTypes = [
    { name: 'tiktok', icon: <IconTiktok /> },
    { name: 'instagram', icon: <IconInstagram /> },
    { name: 'facebook', icon: <IconFacebook /> },
    { name: 'youtube', icon: <IconYoutube /> },
    { name: 'twitch', icon: <IconTwitch /> },
    { name: 'twitter', icon: <IconTwitter /> },
  ];

  const styles = useMemo(() => {
    return {
      socialLogosTab: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
      },
      socialMediaRow: {
        width: '375px',
        height: '48px',
        flexShrink: 0,
        borderRadius: '6px',
        background: '#404040',
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px',
        marginBottom: '9px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease', // Add a transition for smooth highlighting
      },
      icon: {
        width: '24px',
        height: '24px',
        marginRight: '6px',
      },
      text: {
        color: '#FFF',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '21px',
        flex: '1',
        marginBottom: 0,
      },
      inputContainer: {
        display: 'flex',
        alignItems: 'center',
        background: 'rgba(121, 121, 121, 0.50)',
        borderRadius: '4px',
        padding: '0 5px',
        flex: '50%',
      },
      input: {
        width: '170px',
        height: '24px',
        border: 'none',
        background: 'transparent',
        color: '#FFF',
        outline: 'none',
      },
      submitButton: {
        background: '#2C2C2F', // Change the background color as needed
        color: 'white',
        border: 'none',
        borderRadius: '6px',
        padding: '10px 20px',
        cursor: 'pointer',
        marginBottom: '15px', // Add spacing between the rows and the button
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        minWidth: '350px',
      },
    } as const;
  }, []);

  const [highlightedRows, setHighlightedRows] = useState<string[]>([]);
  const [inputTextMap, setInputTextMap] = useState<{ [name: string]: string }>(
    {},
  );

  const handleInputChange = (name: string, text: string) => {
    if (text.length > 20) {
      return null;
    }
    setInputTextMap({
      ...inputTextMap,
      [name]: text,
    });

    if (text && !highlightedRows.includes(name)) {
      setHighlightedRows([...highlightedRows, name]);
    } else if (!text && highlightedRows.includes(name)) {
      setHighlightedRows(highlightedRows.filter((rowName) => rowName !== name));
    }
  };

  const handleOpenPricingModal = () => {
    setIsPricingModalOpened(true);
  };

  const handleClosePricingModal = () => {
    setIsPricingModalOpened(false);
  };

  const handleSubmit = () => {
    if (!elementsTabEnabledForUser) {
      handleOpenPricingModal();
      return;
    }
    // Log the input text for highlighted elements
    highlightedRows.forEach((rowName) => {
      handleChooseLogo(inputTextMap[rowName], rowName, 2);
    });
  };

  const elementsTabEnabledForUser = getEnabledElementsForUser(userContext?.user);

  return (
    <div style={styles.socialLogosTab}>
      <PricingModal isOpen={isPricingModalOpened} handleClosePricingModal={handleClosePricingModal} />

      {socialMediaTypes.map((socialMedia, i) => (
        <div key={socialMedia.name} className={`search-box ${highlightedRows.includes(socialMedia.name) ? 'active' : ''}`}>
          {socialMedia.icon}
          <div className='input-with-at-sign'>
            <span>@</span>
            <SocialInput inputTextMap={inputTextMap} socialMedia={socialMedia} handleInputChange={handleInputChange} />
          </div>
        </div>
      ))}
      <div onClick={handleSubmit} className='green-button-editor' style={{ marginTop: 8 }}>
        Add Stickers
      </div>
    </div>
  );
};

const SocialInput = ({ inputTextMap, socialMedia, handleInputChange }: { inputTextMap: any, socialMedia: any, handleInputChange: any }) => {
  const [inputRef, setInputFocus] = useFocus();

  const handleOnInputClick = (e: any) => {
    e.preventDefault();
    inputRef.current.blur();
    setInputFocus();
    console.log('inputRef.current', inputRef.current);
  }

  return (
    <input
      ref={inputRef}
      onClick={handleOnInputClick}
      maxLength={16}
      type="text"
      defaultValue={inputTextMap[socialMedia.name] || ''}
      onChange={(e) =>
        handleInputChange(socialMedia.name, e.target.value)
      }
      className="search-input"
    />
  )
}

export default SocialLogosTab;
