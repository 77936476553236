import React, { useEffect, useState } from 'react';
import { Calendar, Modal, Tooltip, message } from 'antd';
import moment, { Moment } from 'moment';
import useAxios from 'axios-hooks';
import 'moment/locale/en-ca';
import './SocialCalendar.less';
import { IClip, IClipEdit, IVideoClip } from '../../../data/intefaces/stream.interface';
import { ClipCard } from '../../../components/upload-card/ClipCard';
import { ReactComponent as IconLinkedin } from '../../../assets/linkedin.svg';
import { ReactComponent as IconFacebook } from '../../../assets/social_facebook.svg';
import { ReactComponent as IconInstagram } from '../../../assets/instagram.svg';
import { ReactComponent as IconPinterest } from '../../../assets/pinterest.svg';
import { ReactComponent as IconTiktok } from '../../../assets/tiktok.svg';
import { ReactComponent as IconYoutubeShorts } from '../../../assets/youtube-shorts.svg';



interface ISocialPost {
  post?: {
    mediaUrls: string[];
    scheduleDate?: string;
    profileTitle?: string;
  };
}

const SocialCalendarPage = () => {
  const [postsResponse, postsRefetch] = useAxios('/streamer/social/clips');

  // State for modal visibility and selected clip
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClip, setSelectedClip] = useState<IClip | null>(null);
  const [localPostsList, setLocalPostsList] = useState<IClip[]>([]); // Default to an empty array
  const [forceRenderKey, setForceRenderKey] = useState(0);
  const [highlightedClipId, setHighlightedClipId] = useState<string | null>(null);

  const platformIcons: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
    linkedin: IconLinkedin,
    facebook: IconFacebook,
    instagram: IconInstagram,
    pinterest: IconPinterest,
    tiktok: IconTiktok,
    youtube: IconYoutubeShorts,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLocalPostsList(postsResponse?.data || []);
      } catch (error) {
        console.error('Failed to fetch posts:', error);
      }
    };
  
    fetchData();
  }, [postsResponse]);

  
  const getPlatformIcon = (platformName: string) => {
    const IconComponent = platformIcons[platformName.toLowerCase()];

    return IconComponent ? (
      <IconComponent className='social-media-icon' />
    ) : (
      <span style={{ fontSize: '14px', color: '#888' }}>?</span>
    );
  };

  const formatTimeFromISOString = (isoString: string) => {
    const date = new Date(isoString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${hours}:${formattedMinutes}${period}`;
  };

  // Ant Design message system for notifications
  const [messageApi, contextHolder] = message.useMessage();

  // Opens the modal and sets the selected clip
  const handleClipClick = (clip: IClip) => {
    setSelectedClip(clip);
    setIsModalOpen(true);
  };

  // Closes the modal and clears the selected clip
  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedClip(null);
  };

  // Function to handle successful rescheduling
  const handleSuccessReschedule = async (isCancelPost?: boolean) => {
    try {
      const { data } = await postsRefetch(); // Refetch updated posts list
      setLocalPostsList(data); // Update local state with new data
      setHighlightedClipId(selectedClip?.id || null); // Highlight the last updated clip
      setForceRenderKey((prev) => prev + 1); // Force re-render
  
      const successMessage = isCancelPost
        ? 'Your scheduled post has been canceled.'
        : 'Your post has been successfully rescheduled.';
      messageApi.success(successMessage, 5);
      handleModalClose(); // Close modal after success
    } catch (error) {
      messageApi.error('Failed to update posts. Please try again.');
    }
  };
  const getClipOrientation = (clip: IClip): 'mobile' | 'desktop' => {
    if (clip.mobile_download_url) {
      const found = clip.social_posts?.[0]?.post?.mediaUrls?.find(
        (url) => url === clip.mobile_download_url
      );
      if (found) {
        return 'mobile';
      }
    }
    return 'desktop';
  };


  // Custom renderer for calendar cells
  const dateCellRender = (value: Moment) => {
    const normalizedDate = value.startOf('day');
    const postsForDate = localPostsList.filter((clip) =>
      clip.social_posts?.some((post) =>
        moment(post.post?.scheduleDate).isSame(normalizedDate, 'day')
      )
    );
  
    return (
      <ul className="events">
        {postsForDate.flatMap((clip) =>
          clip.social_posts?.map((post, index) => (
            <li
              key={`${clip.id}-${index}`}
              className={`event-item ${clip.id === highlightedClipId ? 'highlight' : ''}`}
              onClick={() => handleClipClick(clip)} // Pass the specific clip
            >
              {getPlatformIcon(post.post?.platforms[0] || 'unknown')}
              <span>{formatTimeFromISOString(post.post?.scheduleDate || '')}</span>
              <Tooltip overlayClassName="tooltip" title={post.post?.post} placement="top">
                <span className="truncated-text">{post.post?.post}</span>
              </Tooltip>
            </li>
          ))
        )}
      </ul>
    );
  };
  

  return (
    <div id="social-calendar" className="social-calendar-container">
      {/* Message context for notifications */}
      {contextHolder}

      {/* Calendar component with custom cell renderer */}
      <Calendar
        key={forceRenderKey} // Trigger re-render when key changes
        mode="month"
        dateCellRender={dateCellRender}
      />

      {/* Modal to display the selected clip */}
      <Modal
        visible={isModalOpen}
        onCancel={handleModalClose}
        footer={null}
        width={550}
        centered
        closable={false}
      >
        {selectedClip ? (
          <ClipCard
            key={selectedClip.id}
            socialPost={selectedClip.social_posts?.[0]} // Use the first social post for the selected clip
            postsRefetch={postsRefetch}
            analyticsMode
            handleSuccessReschedule={handleSuccessReschedule}
            handleDirectShareClick={() => null}
            clipNumber={1}
            streamTitle=""
            defaultOrientation={getClipOrientation(selectedClip)}
            // defaultOrientation= {'mobile'}
            clip={selectedClip as IClip & Partial<IClipEdit> & IVideoClip}
            platformStreamId=""
            isTranscribePage={false}
            isTwitchPage={false}
          />
        ) : (
          <p>No post selected.</p>
        )}
      </Modal>
    </div>
  );
};

export default SocialCalendarPage;
