import { Col, Modal, Row, Space, Checkbox, Button, Typography, Image } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { COLORS } from '../../themes/colors';
import Title from 'antd/lib/typography/Title';
import { UserContext } from '../../data/userContext';
import { useNavigate } from 'react-router';
import { putUpdateProfile } from '../../services/streamer.service';
import SwitchButton from '../buttons/switch/SwitchButton';
import { ReactComponent as IconTwitch } from '../../assets/icon-twitch-black.svg';
import IconClose from '../../assets/modal-close.svg';
import { Link } from 'react-router-dom';
import { roundWithTwoDecimals } from '../../utils';

type Mode = 'personal' | 'youtube' | 'twitch';

export interface ICheckStatusModalProps {
  open: boolean;
  onClose: () => void;
  onConfim: () => void;
}

export const CheckStatusModal = ({ open, onClose, onConfim }: ICheckStatusModalProps) => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const styles = useMemo(() => {
    return {
      modalStyle: {
        borderRadius: 10,
        overflow: 'hidden',
      },
      modalBody: {
        background: COLORS.MODAL_GRAY_BACKGROUND,
        padding: '40px',
      },
      contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 42
      },
      buttonsContainer: {
        display: 'flex',
        gap: 20,
        marginTop: 10
      },
      buttonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      },
      description: {
        fontSize: 18,
        textAlign: 'center',
        marginTop: 30,
        maxWidth: '66%'
      }
    } as const;
  }, []);




  return (
    <Modal
      open={open}
      footer={null}
      bodyStyle={styles.modalBody}
      style={styles.modalStyle}
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      onCancel={onClose}
      closable={false}
      maskClosable={false}
      width={600}
      centered
    >
      <div style={{ position: 'absolute', top: 20, right: 20 }} className='cursor-pointer' onClick={onClose}>
        <Image src={IconClose} preview={false} width={24} />
      </div>
      <div style={styles.contentWrapper}>
        <Typography style={styles.description}>Usually clips creation takes less then 5 minutes, this video might&apos;ve gotten stuck. You can always refund your credits.</Typography>
        <div style={styles.buttonsContainer}>
          <div style={styles.buttonWrapper}>
            <Button onClick={onConfim} type="primary" className='button'>Refund Credits</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CheckStatusModal;