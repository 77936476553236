import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { IStream } from '../../data/intefaces/stream.interface';
import { StreamCard } from '../stream-card';
import axios from 'axios';
import { EmptyStreamCard } from '../empty-stream.card';
import { ReactComponent as SortIcon } from '../../assets/sort-icon.svg';
import useAxios from 'axios-hooks';
import { UserContext } from '../../data/userContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Divider, Image, Spin, Typography, Skeleton, Row } from 'antd';
import { COLORS } from '../../themes/colors';
import Tour from 'reactour';
import { useWindowDimensions } from '../../pages/Editor/Editor.page';
import { UploadRow } from '../upload-card/UploadRow';
import { UploadCard } from '../upload-card';

const POLLING_INTERVAL = 10000;

export const Spikes = () => {
  const userContext = useContext(UserContext);
  const { pathname, state } = useLocation();
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [dateSortNewest, setDateSortNewest] = useState(true);
  const [openTour, setOpenTour] = useState<boolean>(false);
  const [loadingMissingStreams, setLoadingMissingStreams] = useState(false); // Add loading state
  const { width } = useWindowDimensions();
  const [selectedUploadCard, setSelectedUploadCard] = useState<null | string>(null);
  const navigate = useNavigate();

  const [{ data, error, loading }, refetch] = useAxios('/streamer/streams');
  const streams = data as IStream[];

  useEffect(() => {
    refetch(); // Fetch on the first load
  }, []);

  useEffect(() => {
    if (error) {
      if (error?.response?.status === 403) {
        localStorage.removeItem('user');
        window.location.href = '/';
      }
    }
  }, [error]);

  useEffect(() => {
    const timer = setInterval(refetch, POLLING_INTERVAL);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (pathname == '/spikes' && !loading) {
      refetch();
    }
  }, [pathname]);

  const handleMissingStreamsClick = async () => {
    setLoadingMissingStreams(true); // Set loading state to true when the button is clicked
    try {
      const response = await axios.post('/streamer/find-my-streams');
      // Handle the API response as needed
      console.log('API response:', response.data);
      // You can perform further actions with the response here
    } catch (error) {
      // Handle any API errors here
      console.error('API error:', error);
    } finally {
      setLoadingMissingStreams(false); // Set loading state to false when the API call is done
    }
  };

  const styles = useMemo(() => {
    return {
      pageContainer: {
        maxWidth: 1384,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      },
      topHeadingContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: 33
      },
      topHeading: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '13px',
        width: '100%',
        padding: '0 24px'
      },
      topHeadingText: {
        fontSize: '16px',
        fontWeight: 400,
        color: COLORS.LIGHT_GRAY_TEXT,
        userSelect: 'none'
      },
      sortText: {
        fontSize: '20px',
        fontWeight: 400,
        color: 'var(--text-base)',
        marginLeft: '16px',
        userSelect: 'none'
      },
      bottomDivider: {
        height: 1,
        backgroundColor: COLORS.GRAY_CONTROLS
      },
      horizontalDivider: {
        height: 1.5,
        width: 40,
        backgroundColor: COLORS.GRAY_CONTROLS,
        borderRadius: 2,
        margin: '20px auto',
      },
      missingStreams: {
        padding: '0 16px',
        fontSize: '14px',
        color: 'var(--text-on-color)',
        background: 'var(--bg-brand-secondary)',
        transition: 'background 0.5s', // Add a transition for the background color
        cursor: 'pointer', // Add a pointer cursor for hover effect
      },

    } as const;
  }, []);

  const makeClipsTutorialWatched = localStorage.getItem('makeClipsTutorialWatched');

  useEffect(() => {
    if (!error && streams?.length > 0) {
      if (makeClipsTutorialWatched === 'true') {
        setOpenTour(false);
      } else {
        setOpenTour(true);
      }
    } else {
      setOpenTour(false);
    }

  }, [error, streams, makeClipsTutorialWatched]);

  useEffect(() => {
    const clipId = getLoadingClipId();

    if (clipId && streams) {
      const foundStream = streams.find(stream => stream.clips.find(clip => clip.id === clipId));
      if (foundStream) {
        setSelectedUploadCard(foundStream.id);
      }
    }
  }, []);

  const getLoadingClipId = () => {
    const storedData = localStorage.getItem('loadingClipId');
    return storedData ? JSON.parse(storedData) : null;
  };

  // Function to split the array into chunks
  const chunkArray = (array: any, size: number) => {
    if (!array || array.length < 1) return [];
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  const getClipsPerRow = () => {
    if (width > 1000) return 3;
    else if (width > 720) return 2;
    else return 1;
  };

  const chunkedStreams = chunkArray(streams, getClipsPerRow()); // Split streams into separate array with 3 items in each of it

  const handleClickOnUploadCard = (uploadId: string) => {
    if (selectedUploadCard === uploadId) {
      setSelectedUploadCard(null); // Close
    } else {
      setSelectedUploadCard(uploadId);
    }
  };

  const handleCollapseClips = () => {
    setSelectedUploadCard(null);
  };

  return (
    <div style={styles.pageContainer}>
      {/* <Tour
        rounded={7}
        className="tour-wrapper"
        accentColor="#303030"
        steps={steps}
        isOpen={openTour}
        closeWithMask={false}
        onRequestClose={handleCloseTour} /> */}
      <div style={styles.topHeadingContainer}>
        <div style={styles.topHeading}>
          <Typography style={styles.topHeadingText}>My Streams</Typography>
          <div onClick={() => setDateSortNewest(!dateSortNewest)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <SortIcon style={{ stroke: 'var(--text-base)' }} className={dateSortNewest ? '' : 'rotate-180'} />
            <Typography style={styles.sortText}>Date</Typography>
          </div>
        </div>
        <div style={styles.bottomDivider} />
        <Button
          style={styles.missingStreams} // Add a new style for the button
          type="primary" // You can adjust the button type based on your design
          onClick={handleMissingStreamsClick} // Define a click handler function
          loading={loadingMissingStreams} // Use the loading prop to show a spinner
        >
          {loadingMissingStreams ? 'Checking for new streams' : 'Missing Streams? Click here'}
        </Button>
      </div>


      {streams ?
        <>
          {!error &&
            <div style={{ width: '100%' }}>
              <Row gutter={[24, 24]}>
                {streams ?
                  <>
                    {streams?.length > 0 && streams?.map((stream: any, i) => (
                      <Col key={stream.id} xs={24} md={12} lg={8}><UploadCard selectedUploadCard={selectedUploadCard} key={stream.id} stream={stream} index={i} refetch={refetch} isTwitchCard /></Col>
                    ))}
                  </>
                  :
                  <>
                    <Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} />
                    <Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} />
                    <Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} />
                    <Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} />
                    <Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} />
                  </>
                }
              </Row>
              {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 40 }}>
                {chunkedStreams ?
                  <>
                    {chunkedStreams?.length > 0 &&
                      chunkedStreams.map(streamsArray =>
                        streamsArray?.length > 0 &&
                        <UploadRow stream={streamsArray.find((stream: IStream) => stream.id === selectedUploadCard)} refetch={refetch} handleCollapseClips={handleCollapseClips} isTwitchCard>
                          {(streamsArray?.map((stream: IStream, i: number) => (
                            <Fragment key={stream.id}>
                              <div style={{ flex: 1 }} onClick={() => handleClickOnUploadCard(stream.id)}>
                                <UploadCard selectedUploadCard={selectedUploadCard} key={stream.id} stream={stream} index={i} refetch={refetch} isTwitchCard />
                              </div>
                            </Fragment>
                          )))}
                        </UploadRow>
                      )
                    }
                  </>
                  :
                  <>
                    <Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} />
                    <div style={styles.horizontalDivider} />
                    <Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} />
                  </>
                }
              </div> */}

              {streams?.length == 0 &&
                <EmptyStreamCard />
              }
            </div>
          }
        </>
        :
        <>
          <Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} />
          <div style={styles.horizontalDivider} />
          <Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} />
        </>
      }
    </div>
  );
};

export default Spikes;