import { Col, Row, Button, Input, Pagination, message, Image, Tooltip, Typography } from 'antd';
import { COLORS } from '../../themes/colors';
import { useState, useMemo, useEffect, useContext, useRef } from 'react';
import useAxios from 'axios-hooks';
import { SpikeCard } from '../stream-card/spike-card';
import { IClip } from '../../data/intefaces/stream.interface';
import { EGeneratedClipType } from '../../data/enums/clip-type.enum';
import { AntCloudOutlined } from '@ant-design/icons';
import { ReactComponent as IconTwitch } from '../../assets/icon-twitch-black.svg';
import IconLightning from '../../assets/lightning-icon.svg';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { globalStyles } from '../../utils/globalStyles';
import { UserContext } from '../../data/userContext';
import * as tus from 'tus-js-client';

import './Uploads.less';
import { SpikeCardNew } from '../stream-card/spike-card-new';
import { isMobile } from 'react-device-detect';
import { UploadFromComputerButton } from '../upload-from-computer-button';
import { ClipCard } from '../clip-card';


export const Uploads = ({ tutorialOpen = false, handleCloseTour }: { tutorialOpen?: boolean, handleCloseTour?: () => void }) => {
  const POLLING_INTERVAL = 10000;
  const PAGE_SIZE = 21;

  const [{ data, error, loading }, refetch] = useAxios('/streamer/uploads');

  const clips: IClip[] = data;
  const [currentPage, setCurrentPage] = useState(1);
  const [messageApi, contextHolder] = message.useMessage();
  const [url, setUrl] = useState<string>('');
  const [videoDuration, setVideoDuration] = useState<number | null>(null);
  const [uploadedVideoUrl, setUploadedVideoUrl] = useState<string | null>(null);
  const [videoTitle, setVideoTitle] = useState<string | null>(null);

  const inputRef = useRef(null);
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const isGoogleUser = !userContext?.user?.twitch_id;
  const usedMinutes = userContext?.user?.used_upload_minutes;
  const totalMinutes = userContext?.user?.total_upload_minutes;

  const indexOfLastItem = currentPage * PAGE_SIZE;
  const indexOfFirstItem = indexOfLastItem - PAGE_SIZE;

  const regex = /^(https?:\/\/)?(www\.)?(clips\.)?twitch\.tv\/.*/;

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const timer = setInterval(refetch, POLLING_INTERVAL);
    return () => clearInterval(timer);
  }, []);

  const styles = useMemo(() => {
    return {
      main: {
        background: COLORS.GRAY_BACKGROUND,
        fontSize: 16,
        height: 56,
        padding: '0 25px',
        borderRadius: '8px 0 0 8px',
        width: 500
      },
      button: {
        letterSpacing: 'normal',
        left: 5,
        height: 34,
        width: 100,
        fontSize: 14,
        fontFamily: 'BrandonText',
        border: 2,
        boxShadow: 'rgb(0, 239, 248) 0px 0px 10px'
      },
      uploadButton: {
        letterSpacing: 'normal',
        left: 5,
        height: 34,
        width: 125,
        fontSize: 12,
        fontFamily: 'BrandonText',
        boxShadow: 'rgb(0, 239, 248) 0px 0px 10px'
      },
      searchButton: {
        letterSpacing: 'normal',
        left: 15,
        height: 34,
        width: 195,
        fontSize: 12,
        fontFamily: 'BrandonText',
        boxShadow: 'rgb(0, 239, 248) 0px 0px 10px'
      },
      makeClipsButtonStyle: {
        cursor: 'pointer',
        backgroundColor: COLORS.PRIMARY,
        borderRadius: 6,
        height: 56,
        width: 230,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 14px',
      },
      secondaryButtonStyle: {
        cursor: 'pointer',
        backgroundColor: '#404040',
        borderRadius: 6,
        height: 56,
        width: 230,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 14px',
      },
      uploadFromComputer: {
        cursor: 'pointer',
        backgroundColor: '#404040',
        borderRadius: 6,
        height: 56,
        width: 280,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 14px',
      },
      makeClipsText: {
        fontSize: '20px',
        fontWeight: 400,
        color: COLORS.BACKGROUND_DARK_GRAY,
        marginLeft: '12px',
      },
      uploadFromComputerText: {
        fontSize: '20px',
        fontWeight: 400,
        color: '#D9D9D9',
      },
      secondaryButtonText: {
        fontSize: '20px',
        fontWeight: 400,
        color: '#D9D9D9',
        marginLeft: '12px',
      },
      uploadsResultsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '32px',
        maxWidth: '2020px',
        margin: '0 auto',
        padding: isMobile ? '50px 20px 20px 20px' : '50px 30px 20px 15px'
      }
    } as const;
  }, []);

  const onClick = async () => {
    if (usedMinutes && totalMinutes && (usedMinutes >= totalMinutes)) {
      navigate('/subscriptions', { state: { message: 'Uh-oh! You\'ve hit the maximum upload minutes limit.' } });
    } else {
      if (!regex.test(url)) {
        messageApi.error('Invalid Twitch Clip URL', 5);
        setUrl('');
        return;
      }
      // remove extra params for urls
      let trimmedUrl = url;
      const index = url.indexOf('?');
      if (index >= 0) {
        trimmedUrl = url.substring(0, index);
      }

      const response = await axios.post('/streamer/create-user-clip', { url: trimmedUrl })
        .catch((error) => {
          if (error?.response) {
            console.error(error.response?.data);
          }
        });

      switch (response?.data) {
        case 'exists': {
          messageApi.error('Twitch clip already exists', 5);
          break;
        }
        case 'not found': {
          messageApi.error('Twitch clip was not found', 5);
          break;
        }
        case 'max reached': {
          navigate('/subscriptions', { state: { message: 'Uh-oh! You\'ve hit the maximum upload minutes limit.' } });
          break;
        }
        default: {
          messageApi.success('Clip uploading...', 5);
          userContext?.setUser(response?.data);
          break;

        }
      }

      setUrl('');
    }


  };

  const handleShowTutorialInProgress = () => {
    messageApi.info('If you ever want a refresher, the tutorial is waiting for you in the dropdown. 😊', 5);
  };

  const handleMyClipsClick = () => {
    if (tutorialOpen && handleCloseTour) {
      handleShowTutorialInProgress();
      handleCloseTour();
    }
    navigate(`/spikes/search-twitch-clips?username=${userContext?.user?.username}`);
  };

  const handleUploadFromTwitchClick = () => {
    if (tutorialOpen && handleCloseTour) {
      handleShowTutorialInProgress();
      handleCloseTour();
    }
    navigate('/spikes/search-twitch-clips');
  };

  const handleDeleteClip = async (clipId: string) => {
    const response = await axios.put('/streamer/update-favorite-clip', {
      clip: clipId,
      is_removed_from_favorite: true
    }).catch((error) => {
      if (error.response) {
        console.log(error.response);
      }
    });

    if (response?.status === 200) {
      refetch();
      messageApi.success('Clip removed!', 3);
    }
  };

  // Add 'MODE' param later
  const handleSendUploadedVideoUrl = async () => {
    if (usedMinutes && totalMinutes && (usedMinutes >= totalMinutes)) {
      navigate('/subscriptions', { state: { message: 'Uh-oh! You\'ve hit the maximum upload minutes limit.' } });
    } else {
      const response = await axios.post('/streamer/create-ai-video', { platform_url: uploadedVideoUrl || url, platform: 'personal', duration: videoDuration, title: videoTitle })
        .catch((error) => {
          if (error.response) {
            console.log('error');
          }
        });

      if (response) {
        messageApi.success('Clips uploading...', 5);
      }
      setVideoDuration(null);
      setUploadedVideoUrl(null);
      setUrl('');
    }
  };

  return (
    <>
      <div style={{ color: 'black', display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
        {contextHolder}
        <Input placeholder='Twitch Clip URL' value={url} style={styles.main} onChange={(e) => setUrl(e.target.value)} />
        <div onClick={onClick} style={styles.makeClipsButtonStyle}>
          <Image src={IconLightning} preview={false} />
          <Typography style={styles.makeClipsText}>Get Clip</Typography>
        </div>
      </div>
      <div>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>

          {/* <UploadFromComputerButton fromUpload setVideoDuration={setVideoDuration} setUploadedVideoUrl={setUploadedVideoUrl} setTitle={setVideoTitle} handleSuccess={handleSendUploadedVideoUrl} /> */}

          {!isGoogleUser &&
            <>
              <div data-tour="my-twitch-clips" onClick={handleMyClipsClick} style={{ ...styles.secondaryButtonStyle, marginRight: 30 }}>
                <Image src={IconLightning} preview={false} />
                <Typography style={styles.secondaryButtonText}>My Twitch Clips</Typography>
              </div>

              <div data-tour="upload-from-twitch" onClick={handleUploadFromTwitchClick} style={{ ...styles.secondaryButtonStyle, minWidth: 270 }}>
                <IconTwitch width={24} height={24} style={{ color: 'black', marginRight: 5, marginLeft: -3 }} />
                <Typography style={styles.secondaryButtonText}>Upload from Twitch</Typography>
              </div>
            </>
          }
        </div>
      </div>
      <div>
        {/* <div style={{ ...styles.uploadsResultsContainer, justifyContent: 'center' }}>
          {clips?.map((clip: any, i: number) => (
            <>
              <ClipCard clip={clip} handleDeleteClip={handleDeleteClip} />
            </>
          ))}
        </div> */}
        <div style={{ ...styles.uploadsResultsContainer, justifyContent: clips?.slice(indexOfFirstItem, indexOfLastItem).length > 2 || isMobile ? 'center' : 'flex-start' }}>
          {clips?.slice(indexOfFirstItem, indexOfLastItem).map((clip: any, i: number) => (
            <>
              <SpikeCardNew
                clip={clip}
                platformStreamId={clip.stream ? clip.stream.platform_stream_id : null}
                isVisible
                isTwitchCard={clip.clip_type === EGeneratedClipType.TWITCH_UPLOAD}
                isUploadsTab
                onRemoveCard={handleDeleteClip}
                enablePreview
              />
            </>
          ))}
        </div>
        <Row>
          <Col span={54}>
            <Pagination
              current={currentPage}
              pageSize={PAGE_SIZE}
              total={clips?.length}
              onChange={handlePageChange}
              simple={true}
              hideOnSinglePage={true}
              showQuickJumper={false}
              style={{ marginTop: 30, marginLeft: 630 }}
            />
          </Col>
        </Row>
      </div >
    </>
  );
};

export default Uploads;