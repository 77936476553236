import React from 'react';
import { Typography, Row, Col, Layout } from 'antd';
import './LiveStreamers.less';
import TemplateBanner from './../../assets/template-banner1.png';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

import FooterResponsive from '../../components/footer/FooterResponsive';
import HeaderResponsive from '../../components/header/header-responsive/HeaderResponsive';

const LiveStreamersPage: React.FC = () => {
  return (
    <Layout
      className="layout"
      style={{
        minHeight: '100vh',
        background: '#080808',
      }}
    >
      <HeaderResponsive />
      <Content className="live-streamers-page">
        <div className="live-streamers-header">
          <Title level={2}>Elevate Your Live Streaming Game with Spikes</Title>
        </div>

        <div className="live-streamers-section">
          <Title level={3}>Introduction:</Title>
          <Paragraph>
            Imagine this: you are a passionate live streamer, connecting with
            your audience in real-time, and your content is engaging and
            exciting. But there is one thing missing—the ability to create
            captivating highlights and edit clips to share on your social media
            platforms. That is where Spikes comes in.
          </Paragraph>
        </div>

        <div className="live-streamers-section">
          <Title level={3}>Case Story:</Title>
          <Paragraph>
            Meet Sarah, a talented live streamer who wants to take her content
            to the next level. With Spikes, she can enhance her live streaming
            experience by using the powerful clip editor. She trims her videos,
            adjusts the format size, and even adds a camera feed to provide a
            more immersive viewing experience.
          </Paragraph>
        </div>
        <div className="live-streamers-section">
          <Paragraph>
            But thats not all. Spikes leverages advanced AI technology to
            automatically capture Sarahs best moments from her entire live
            stream. No more spending hours manually searching for those epic
            moments—Spikes does the work for her, saving her valuable time and
            effort.
          </Paragraph>
        </div>
        <div className="live-streamers-section">
          <Paragraph>
            With Spikes intelligent filter, Sarah can curate and filter her
            videos based on the emotions expressed during her live streams. This
            allows her to create a more personalized and authentic connection
            with her audience, showcasing the moments that truly resonate.
          </Paragraph>
        </div>
        <div className="live-streamers-section">
          <Paragraph>
            Meet Sarah, a talented live streamer who wants to take her content
            to the next level. With Spikes, she can enhance her live streaming
            experience by using the powerful clip editor. She trims her videos,
            adjusts the format size, and even adds a camera feed to provide a
            more immersive viewing experience.
          </Paragraph>
        </div>
        <div className="live-streamers-section">
          <Paragraph>
            Sarah is impressed with Spikes seamless integration and its
            extensive 500 GB storage capacity, providing her with ample space to
            store all her valuable content.
          </Paragraph>
        </div>
        <div className="live-streamers-section">
          <Row justify="center">
            <Col xs={24} sm={24} md={24}>
              <div className="image-container">
                <img
                  src={TemplateBanner}
                  alt="live streamers"
                  className="live-streamers-image"
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="live-streamers-section">
          <Title level={3}>Conclusion:</Title>
          <Paragraph>
            Thanks to Spikes, Sarahs live streaming journey has been
            transformed. She can now effortlessly create professional
            highlights, engage her audience with automated subtitles, and let AI
            capture her best moments from her live streams. Its time for you to
            join Sarah and elevate your live streaming game with Spikes.
          </Paragraph>
        </div>
      </Content>
      <FooterResponsive />
    </Layout>
  );
};

export default LiveStreamersPage;
